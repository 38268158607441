import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {KTSVG} from 'src/_metronic/helpers'
import {CashierModel, UserModel} from '../modules/auth/models/UserModel'
import useCountDown from 'react-countdown-hook'
import moment from 'moment'

const ModalAlertCheckout = () => {
  const [show, setShow] = useState(false)
  const [timeLeft, {start}] = useCountDown(10000, 1000)
  const [okayed, setOkayed] = useState(false)

  const user = useSelector<RootState>(({auth}: any) => auth.user, shallowEqual) as UserModel
  const cashier = useSelector<RootState>(
    ({auth}: any) => auth.cashier,
    shallowEqual
  ) as CashierModel

  const handleClose = () => setShow(false)

  useEffect(() => {
    if (cashier?.start) {
      const date = new Date(cashier?.end).getTime()
      const now = new Date().getTime()
      const distance = date - now
      start(distance)
    }
  }, [cashier])

  useEffect(() => {
    if (!okayed && timeLeft < 660000 && timeLeft > 540000) {
      setShow(true)
      setOkayed(true)
    }
  }, [timeLeft])

  return (
    <Modal
      id='kt_modal_kitchen_display'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={handleClose}
      centered
    >
      <div className='card'>
        <div className='modal-header py-4 d-flex justify-content-between'>
          <span className='fs-3 fw-bolder'>Peringatan Check Out</span>
          {/* begin::Close */}
          <div className='btn btn-icon btn-transparent' onClick={handleClose}>
            <KTSVG className='svg-icon-2' path='/media/icons/efood/Close.svg' />
          </div>
          {/* end::Close */}
        </div>

        <div className='card-body'>
          Hi, {user?.name}. Harap lakukan check out pada Pkl {moment(cashier?.end).format('HH:mm')}.
        </div>

        <div className='card-footer d-flex justify-content-end'>
          <button type='button' className='btn btn-secondary' onClick={handleClose}>
            Okay
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalAlertCheckout
