/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useState} from 'react'
import {UpdateStatus} from 'src/electron/models/UpdateStatus'
import {useLayout} from '../core'

const Footer: FC = () => {
  const {classes} = useLayout()
  const [updateInfo, setUpdateInfo] = useState<UpdateStatus>()

  const updateStatusHandler = useCallback(
    (event: any, info: UpdateStatus) => setUpdateInfo(info),
    []
  )

  useEffect(() => {
    if (window.eApi?.listenUpdateStatus) {
      window.eApi?.versionUpdateStatus().then(setUpdateInfo)
      window.eApi?.listenUpdateStatus(updateStatusHandler)
      return () => window.eApi?.unlistenUpdateStatus(updateStatusHandler)
    }
  }, [updateStatusHandler])
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='#' className='text-gray-800 text-hover-primary'>
            eFOOD
          </a>
          {!!window.eApi?.version && ` v${window.eApi?.version()}`}
          {updateInfo?.status === 'downloading' &&
            ` - Downloading Update ${Math.floor(updateInfo?.progress?.percent ?? 0)}%`}
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
