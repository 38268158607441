/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import AuthScreens from './screens'
import Swal from 'sweetalert2'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import GuestWrapper from './components/GuestWrapper'

export function AuthPage() {
  const showModalAlertCheckIn = useSelector<RootState>(
    ({auth}: any) => auth.showModalAlertCheckIn,
    shallowEqual
  ) as boolean

  useEffect(() => {
    if (showModalAlertCheckIn) {
      Swal.fire({
        title: '',
        text: 'Gagal login. Anda melakukan check in di luar shift kasir.',
        icon: 'error',
        confirmButtonText: 'Okay, kembali',
      })
    }
  }, [showModalAlertCheckIn])

  return (
    <GuestWrapper>
      <Switch>
        <Route path={AuthScreens.LOGIN_EMAIL.PATH} component={AuthScreens.LOGIN_EMAIL.COMPONENT} />
        <Route path={AuthScreens.LOGIN_PHONE.PATH} component={AuthScreens.LOGIN_PHONE.COMPONENT} />
        <Route path={AuthScreens.FORGOT.PATH} component={AuthScreens.FORGOT.COMPONENT} />
        <Route path='/auth/registration' component={Registration} />
        <Redirect from='/auth' exact={true} to='/auth/login-phone' />
        <Redirect to='/auth/login-phone' />
      </Switch>
    </GuestWrapper>
  )
}
