import {ChangePassword} from './components/ChangePassword'
import {Profile} from './components/Profile'

const UserScreens = {
  PROFILE: {
    COMPONENT: Profile,
    PATH: '/user/profile',
  },
  CHANGE_PASSWORD: {
    COMPONENT: ChangePassword,
    PATH: '/user/change-password',
  },
}

export default UserScreens
