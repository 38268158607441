import {CreateNewPassword} from './components/CreateNewPassword'
import EmailVerification from './components/EmailVerification'
import {ForgotPassword} from './components/ForgotPassword'
import {LoginEmail} from './components/LoginEmail'
import {LoginPhone} from './components/LoginPhone'
import PhoneVerification from './components/PhoneVerification'
import VerificationSuccess from './components/VerificationSuccess'

const AuthScreens = {
  LOGIN_EMAIL: {
    COMPONENT: LoginEmail,
    PATH: '/auth/login-email',
  },
  LOGIN_PHONE: {
    COMPONENT: LoginPhone,
    PATH: '/auth/login-phone',
  },
  FORGOT: {
    COMPONENT: ForgotPassword,
    PATH: '/auth/forgot-password',
  },
  CREATE_PASSWORD: {
    COMPONENT: CreateNewPassword,
    PATH: '/auth/create-password',
  },
  PHONE_VERIFICATION: {
    COMPONENT: PhoneVerification,
    PATH: '/auth/phone-verification',
  },
  EMAIL_VERIFICATION: {
    COMPONENT: EmailVerification,
    PATH: '/auth/email-verification',
  },
  VERIFICATION_SUCCESS: {
    COMPONENT: VerificationSuccess,
    PATH: '/auth/verification-success',
  },
}

export default AuthScreens
