import OneSignalReact from 'react-onesignal'
import {UserModel} from '../modules/auth/models/UserModel'

export const setNotificationTags = (user: UserModel) => {
  if (user?.store) {
    const tags = {
      store: user.store?.id,
    }
    window.eApi?.version() ? window.eApi?.setTags(tags) : OneSignalReact.sendTags(tags)
  }
}

export const clearNotificationTags = () => {
  window.eApi?.version()
    ? window.eApi?.removeTags(['store'])
    : OneSignalReact.getTags((tagsReceived) => {
        OneSignalReact.deleteTags(Object.keys(tagsReceived))
      })
}

export const listenPermission = (onPermissionAllowed: (allowed: boolean) => void) => {
  OneSignalReact.on('subscriptionChange', onPermissionAllowed)
}

export const unlistenPermission = (onPermissionAllowed: (allowed: boolean) => void) => {
  OneSignalReact.off('subscriptionChange', onPermissionAllowed)
}

export const listenNotification = (onNotificationDisplayed: (event: any) => void) => {
  window.eApi?.version()
    ? window.eApi?.listenShowNotification(onNotificationDisplayed)
    : OneSignalReact.on('notificationDisplay', onNotificationDisplayed)
}

export const unlistenNotification = (onNotificationDisplayed: (event: any) => void) => {
  window.eApi?.version()
    ? window.eApi?.unlistenShowNotification(onNotificationDisplayed)
    : OneSignalReact.off('notificationDisplay', onNotificationDisplayed)
}

export const listenClickNotification = (
  onNotificationDisplayed: (event: any, data: any) => void
) => {
  if (window.eApi?.version()) window.eApi?.listenClickNotification(onNotificationDisplayed)
}

export const unlistenClickNotification = (
  onNotificationDisplayed: (event: any, data: any) => void
) => {
  if (window.eApi?.version()) window.eApi?.unlistenClickNotification(onNotificationDisplayed)
}
