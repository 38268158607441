const UserHermesScreens = {
  LIST_USER_CORPORATE: {
    PATH: '/user-hermes/corporate',
  },
  CREATE_USER_CORPORATE: {
    PATH: '/user-hermes/corporate/create',
  },
  DETAIL_USER_CORPORATE: {
    PATH: '/user-hermes/corporate/detail/:id',
    buildPath: (id?: string) => `/user-hermes/corporate/detail/${id}`,
  },
  LIST_SUBMISSION_USER_CORPORATE: {
    PATH: '/user-hermes/list-submission-user-corporate',
  },
  DETAIL_SUBMISSION_USER_CORPORATE: {
    PATH: '/user-hermes/list-submission-user-corporate/:id',
  },
  USER_BRAND: {
    PATH: '/user-hermes/brand',
  },
  USER_BRAND_CREATE: {
    PATH: '/user-hermes/brand/create',
  },
  USER_BRAND_DETAIL: {
    PATH: '/user-hermes/brand/detail/:id',
    buildPath: (id?: string) => `/user-hermes/brand/detail/${id}`,
  },
  ADD_USER_CORPORATE: {
    PATH: '/user-hermes/list-user-corporate/create-user',
  },
  USER_STORE_LIST: {
    PATH: '/user-hermes/store'
  },
  USER_STORE_DETAIL: {
    PATH: '/user-hermes/store/detail/:id',
    buildPath: (id?: string) => `/user-hermes/store/detail/${id}`,
  },
  USER_STORE_ADD: {
    PATH: '/user-hermes/store/create-user'
  }
}

export default UserHermesScreens
