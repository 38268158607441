import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import Select, {SelectComponentsConfig} from 'react-select'
import {ReactSelectMetronicTheme} from './CustomReactSelect'
import * as Yup from 'yup'
import {
  getIncomeRecaps,
  getIncomeRecapsPrint,
  getListManager,
  setCashierCheckOut,
  verifyLogin,
} from '../modules/auth/redux/AuthCRUD'
import {RootState} from 'src/setup'
import {CashierModel, UserModel} from '../modules/auth/models/UserModel'
import useDebounce from '../hooks/useDebounce'
import InlineSVG from 'react-inlinesvg/esm'
import AuthRedux from '../modules/auth/redux/AuthRedux'
import {useHistory, useLocation} from 'react-router'
import moment from 'moment'
import 'moment/locale/id'
import usePrevious from '../hooks/usePrevious'
import AlertError from './AlertError'
import {getErrorMessage} from '../utils/api-utils'
moment.locale('id')

const initialValues = {
  manager: {
    label: '',
    value: '',
    email: '',
  },
  password: '',
  cash: 0,
}

const validationSchema = Yup.object().shape({
  manager: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
      email: Yup.string(),
    })
    .required('Nama Manager wajib dipilih'),
  password: Yup.string()
    .min(6, 'Password minimal 6 karakter')
    .required('Password Manager wajib diisi'),
  cash: Yup.number().required('Petty Cash wajib diisi'),
})

const ModalCheckOut = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [recaps, setRecaps] = useState<any[]>([])
  const [managers, setManagers] = useState<{value: string; label: string}[]>([])
  const dispatch = useDispatch()
  const user = useSelector<RootState>(({auth}: any) => auth.user, shallowEqual) as UserModel
  const cashier = useSelector<RootState>(
    ({auth}: any) => auth.cashier,
    shallowEqual
  ) as CashierModel
  const showModalCheckOut = useSelector<RootState>(
    ({auth}: any) => auth.showModalCheckOut,
    shallowEqual
  ) as boolean
  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema,
    onSubmit: () => {},
  })
  const passwordDebounce = useDebounce(formik.values.password, 500)
  const history = useHistory()
  const location = useLocation()
  const locationPrev: any = usePrevious(location)

  useEffect(() => {
    if (location.pathname !== locationPrev?.pathname && showModalCheckOut) {
      dispatch(AuthRedux.actions.setModalCheckOut(false))
    }
  }, [location, locationPrev, showModalCheckOut])

  const handleCheckOut = () => {
    if (page === 1) {
      setLoading(true)
      verifyLogin(formik.values.manager.value, passwordDebounce)
        .then(() => {
          setPage(2)
          getIncomeRecaps(cashier.id)
            .then((res) => {
              setLoading(false)
              setRecaps(res.data.data)
            })
            .catch(() => {
              setLoading(false)
            })

          setLoading(false)
        })
        .catch(() => {
          formik.setFieldError('password', 'Password tidak sesuai')
          setLoading(false)
        })
    } else {
      setCashierCheckOut(formik.values.manager.value)
        .then(() => {
          getIncomeRecapsPrint(cashier.id).then(({data}) => {
            window.open(data.data.url, '_blank')
            setTimeout(() => {
              setLoading(false)
              history.push('/thank-you')
            }, 200)
          })
        })
        .catch((error) => {
          setLoading(false)
          formik.setStatus(getErrorMessage(error, true) || 'Checkout tidak berhasil.')
          // history.push('/thank-you')
        })
    }
  }

  useEffect(() => {
    getListManager(user?.store?.id, {limit: 999999})
      .then((value) => {
        const items = value.data.data?.items
        setManagers(items?.map((it: any) => ({value: it.id, label: it.name, email: it.email})))
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [user])

  return (
    <Modal
      id='kt_modal_kitchen_display'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={showModalCheckOut}
      onHide={() => {}}
      centered
    >
      <div className='card'>
        <AlertError message={formik.status} handleClose={() => formik.setStatus('')} />

        <div className='modal-header py-2 d-flex justify-space-between py-7'>
          <span className='fs-3 fw-bolder'>Check Out</span>
        </div>

        {page === 1 ? (
          <div className='card-body'>
            <p className='mb-2 fs-6 fw-bolder'>Terimakasih untuk hari ini, {user?.name} !</p>
            <p className='mb-10 fs-6 fw-bolder'>
              Shift: {moment(cashier?.start).format('dddd, DD/MM/YYYY HH:mm')} -{' '}
              {moment(cashier?.end).format('dddd, DD/MM/YYYY HH:mm')}.
            </p>

            <div className='mb-10'>
              <label className='flex-fill form-label fs-6 fw-bold text-gray-800'>
                Nama Manager*
              </label>
              <Select
                autoComplete='off'
                components={ReactSelectMetronicTheme as SelectComponentsConfig<any, true>}
                value={formik.getFieldProps('manager').value}
                onChange={(value: any) => {
                  formik.setFieldValue('manager', value)
                }}
                options={managers}
                placeholder='Pilih Manager'
              />
              {formik.touched.manager && formik.errors.manager && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.manager}</span>
                </div>
              )}
            </div>

            <div>
              <label className='flex-fill form-label fs-6 fw-bold text-gray-800'>
                Password Manager*
              </label>
              <input
                {...formik.getFieldProps('password')}
                className='form-control form-control-lg form-control-solid'
                type='password'
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className='card-body'>
            <div className='d-flex align-items-center mb-2'>
              <InlineSVG src='/media/icons/efood/IconStorePrimary.svg' />
              <span className='ms-3 fs-6 fw-bold text-gray-600'>Store</span>
            </div>
            <p className='fw-bold fs-6 mb-10'>{user?.store?.name}</p>

            <div className='d-flex align-items-center mb-2'>
              <InlineSVG src='/media/icons/efood/IconMoneyPrimary.svg' />
              <span className='ms-3 fs-6 fw-bold text-gray-600'>Pendapatan Tunai</span>
            </div>
            <p className='fw-bold fs-6 mb-10'>
              Rp
              {Number(
                recaps.find((item: any) => item?.offline_payment_method?.is_cash)?.total ?? 0
              ).toLocaleString('id-ID')}
            </p>

            <div className='d-flex align-items-center mb-2'>
              <InlineSVG src='/media/icons/efood/IconMoneyPrimary.svg' />
              <span className='ms-3 fs-6 fw-bold text-gray-600'>
                Pendapatan Metode Pembayaran Lainnya
              </span>
            </div>
            <table className='mb-10'>
              <tbody>
                {recaps?.filter((item: any) => !item?.offline_payment_method?.is_cash)?.length
                  ? recaps
                      ?.filter((item: any) => !item?.offline_payment_method?.is_cash)
                      ?.map((item: any) => (
                        <tr key={item?.offline_payment_method_id}>
                          <td className='fw-bold fs-6 pb-3'>
                            {item?.offline_payment_method?.name}
                          </td>
                          <td className='fw-bold fs-6 pb-3'>
                            : Rp{Number(item?.total ?? 0).toLocaleString('id-ID')}
                          </td>
                        </tr>
                      ))
                  : null}
              </tbody>
            </table>
          </div>
        )}

        <div className='card-footer d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-light me-5'
            onClick={() => {
              if (page === 1) {
                dispatch(AuthRedux.actions.setModalCheckOut(false))
              } else {
                setPage(1)
              }
            }}
          >
            Kembali
          </button>
          <button
            type='button'
            className='btn btn-secondary'
            disabled={loading || !formik.values.manager.value || !passwordDebounce}
            onClick={handleCheckOut}
          >
            Check Out
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalCheckOut
