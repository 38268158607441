import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

namespace UserHermesRedux {
  const STORAGE_KEY = 'user-hermes'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    GetCorporateUserHermes: '[Get List UserHermes] Action',
    GetDataUserHermes: '[Get Data UserHermes] Action',
    IsLoading: '[IsLoading] Action',
  }

  const initialUserHermesState: IUserHermesState = {
    data: undefined,
    isLoading: undefined,
    message: undefined,
  }

  export interface IUserHermesState {
    data?: any
    isLoading?: boolean
    message?: string
  }

  export const reducer = persistReducer(
    {storage, key: STORAGE_KEY, whitelist: ['data', 'isLoading', 'message']},
    (state: IUserHermesState = initialUserHermesState, action: ActionWithPayload<IUserHermesState>) => {
      const data = action.payload?.data
      const message = action.payload?.message
      const isLoading = action.payload?.isLoading

      switch (action.type) {
        case actionTypes.GetCorporateUserHermes:
          return {...state, data, message, isLoading: false}
        case actionTypes.IsLoading:
          return {...state, isLoading}
        default:
          return state
      }
    }
  )

  export const actions = {
    getListCorporateUserHermes: (data: any, message: string) => ({
      type: actionTypes.GetCorporateUserHermes,
      payload: {data, message},
    }),
    setIsLoading: (isLoading: boolean) => ({
      type: actionTypes.IsLoading,
      payload: {isLoading},
    }),
  }
}

export default UserHermesRedux
