import axios from 'axios'
import {BaseResponse, Pagination} from 'src/app/models/api.types'
import {CalculateFeePayload} from '../models/CalculateFeePayload'
import {OfflineOrderPayload} from '../models/LocalCart'
import {
  ManualOrderData,
  Order,
  RejectOrderData,
  RejectOrderReason,
  UpdateParamsModel,
} from '../models/Order'
import {OrderStatistic} from '../models/OrderStatistic'
import {PaymentMethod} from '../models/PaymentMethod'

export const ORDER = 'api/v1/orders'
export const DELIVERY = 'api/v1/deliveries/orders'
export const EFOOD_ORDER = 'api/v1/orders/online-orders/stores'
export const LIST_ORDER = 'api/v1/orders/orders'
export const CANCELLATION = `api/v1/orders/offline-orders/cancellations`

export const UPDATE_ORDER = (
  store_id: string,
  order_id: string,
  type: 'preparing' | 'cooking' | 'packaging' | 'ready-to-pickup' | 'enroute' | 'completed' | string
) => `api/v1/orders/${store_id}/${order_id}/statuses/${type}`
export const OFFLINE_PAYMENT_METHOD = 'api/v1/orders/offline-payment-methods'

export function getListOrder(
  store_id: string,
  params: {
    page?: number
    limit?: number
    no?: string
    groups?: string[]
    delivery_type?: string
    statuses?: string[]
  },
  cancelToken?: any
) {
  return axios.get<BaseResponse<Pagination<Order>>>(`${EFOOD_ORDER}/${store_id}`, {
    params,
    cancelToken,
  })
}

export function getListOrderAll(
  store_id: string,
  params: {
    page?: number
    limit?: number
    no?: string | number
    groups?: string[]
    delivery_type?: string
    statuses?: string[]
    store_id?: string
    merchant_id?: string
  },
  cancelToken?: any
) {
  return axios.get<BaseResponse<Pagination<Order>>>(
    store_id ? `${LIST_ORDER}/all/${store_id}` : `${LIST_ORDER}/all`,
    {
      params,
      cancelToken,
    }
  )
}

export function getListOrderHistory(
  store_id: string,
  params: {
    page?: number
    limit?: number
    no?: string | number
    groups?: string[]
    delivery_type?: string
    statuses?: string[]
    store_id?: string
    merchant_id?: string
    sales_channel_id?: string
  },
  cancelToken?: any
) {
  return axios.get<BaseResponse<Pagination<Order>>>(`${LIST_ORDER}/all/stores/${store_id}`, {
    params,
    cancelToken,
  })
}

export function getDetailOrderHistory(
  store_id: string,
  order_id: string,
  params?: {
    page?: number
    limit?: number
    no?: string | number
    groups?: string[]
    delivery_type?: string
    statuses?: string[]
    store_id?: string
    merchant_id?: string
  },
  cancelToken?: any
) {
  return axios.get(`${LIST_ORDER}/all/stores/${store_id}/${order_id}`, {
    params,
    cancelToken,
  })
}

export function getListOrderAllStores(
  store_id: string,
  params: {
    page?: number
    limit?: number
    no?: string
    groups?: string[]
    delivery_type?: string
    statuses?: string[]
    sales_channel_id?: string
  },
  cancelToken?: any
) {
  return axios.get<BaseResponse<Pagination<Order>>>(`${LIST_ORDER}/all/stores/${store_id}`, {
    params,
    cancelToken,
  })
}

export function updateOrder({store_id, order_id, type}: UpdateParamsModel, cancelToken?: any) {
  return axios.put(UPDATE_ORDER(store_id, order_id, type), null, {
    cancelToken,
  })
}

export function cancelOrder(
  store_id: string,
  order_id: string,
  {reason, menu_ids, variation_ids, other_reasons}: RejectOrderData,
  manager_id?: string,
  cancelToken?: any
) {
  let payload: Record<string, any> | undefined = undefined
  if (reason === RejectOrderReason.MENU_VARIASI_HABIS) {
    payload = {menu_ids, variation_ids}
  } else if (other_reasons) {
    payload = {reason: other_reasons}
  }
  if (manager_id) payload = {manager_id, ...payload}
  return axios.post(`${ORDER}/cancellations/${store_id}/${reason}/${order_id}`, payload, {
    cancelToken,
  })
}

export function cancelOrderPOS(
  store_id: string,
  order_id: string,
  {reason, menu_ids, variation_ids, other_reasons}: RejectOrderData,
  manager_id?: string,
  cancelToken?: any
) {
  let payload: Record<string, any> | undefined = undefined
  if (reason === RejectOrderReason.MENU_VARIASI_HABIS) {
    payload = {menu_ids, variation_ids}
  } else if (other_reasons) {
    payload = {reason: other_reasons}
  }
  return axios.post(
    `${CANCELLATION}/${store_id}/${reason}/${order_id}`,
    {manager_id, ...payload},
    {
      cancelToken,
    }
  )
}

export function manualDelivery(
  store_id: string,
  order_id: string,
  payload: ManualOrderData,
  cancelToken?: any
) {
  return axios.put(`${ORDER}/${store_id}/${order_id}/manual-driver`, payload, {
    cancelToken,
  })
}

export function retryDelivery(store_id: string, order_id: string, cancelToken?: any) {
  return axios.post(`${ORDER}/${store_id}/${order_id}/reordered-driver`, {
    cancelToken,
  })
}

export function cancelDelivery(order_id: string, cancelToken?: any) {
  return axios.delete(`${DELIVERY}/${order_id}`, {
    cancelToken,
  })
}

export function getOfflinePaymentMethod(
  params: {page?: number; limit?: number; status: 'ACTIVE' | 'INACTIVE'},
  cancelToken?: any
) {
  return axios.get<BaseResponse<Pagination<PaymentMethod>>>(OFFLINE_PAYMENT_METHOD, {
    params,
    cancelToken,
  })
}

export function calculateFee(payload: CalculateFeePayload, cancelToken?: any) {
  return axios.post<BaseResponse<Order>>(`${ORDER}/cart/offline-orders/calculation`, payload, {
    cancelToken,
  })
}

export function createOfflineOrder(payload: OfflineOrderPayload, id?: string, cancelToken?: any) {
  if (id)
    return axios.post<BaseResponse<{order: Order}>>(
      `${ORDER}/offline-orders/holds/${id}`,
      payload,
      {cancelToken}
    )
  return axios.post<BaseResponse<{order: Order}>>(`${ORDER}/offline-orders`, payload, {cancelToken})
}

export function holdOfflineOrder(payload: OfflineOrderPayload, id?: string, cancelToken?: any) {
  if (id) return axios.put(`${ORDER}/offline-orders/holds/${id}`, payload, {cancelToken})
  else return axios.post(`${ORDER}/offline-orders/holds`, payload, {cancelToken})
}

export function deleteHoldOrder(id: string, cancelToken?: any) {
  return axios.delete(`${ORDER}/offline-orders/holds/${id}`, {cancelToken})
}

export function printStruk(store_id: string, order_id: string, cancelToken?: any) {
  return axios.post<BaseResponse<{url: string}>>(
    `${ORDER}/${store_id}/${order_id}/print`,
    undefined,
    {
      cancelToken,
    }
  )
}

export function reprintStruk(store_id: string, order_id: string, cancelToken?: any) {
  return axios.post<BaseResponse<{url: string}>>(
    `${ORDER}/${store_id}/${order_id}/print`,
    undefined,
    {
      cancelToken,
    }
  )
}

export function getOrderStats(store_id: string, cancelToken?: any) {
  return axios.get<BaseResponse<OrderStatistic>>(`${ORDER}/${store_id}/stats`, {
    cancelToken,
  })
}
