import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

namespace BrandRedux {
  const STORAGE_KEY = 'hermes-brand'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    GetListBrand: '[Get List Brand] Action',
    GetBrandDetail: '[Get Detail Brand] Action',
    GetDataBrand: '[Get Data Brand] Action',
    GetMenuPriceCategory: '[Get Menu Price Category] Action',
    GetMenuSalesChannel: '[Get Menu Sales Channel] Action',
    GetMenuVariation: '[Get Menu Variation] Action',
    SetSuccess: '[Set Success] Action',
    IsLoading: '[IsLoading] Action',
  }

  const initialBrandState: IBrandState = {
    brand: undefined,
    data: undefined,
    isLoading: undefined,
    message: undefined,
  }

  export interface IBrandState {
    brand?: any
    data?: any
    menuPriceCategory?: any
    menuSalesChannel?: any
    menuVariation?: any
    isLoading?: boolean
    message?: string
  }

  export const reducer = persistReducer(
    {
      storage,
      key: STORAGE_KEY,
      whitelist: [
        'data',
        'isLoading',
        'message',
        'menuPriceCategory',
        'menuSalesChannel',
        'menuVariation',
      ],
    },
    (state: IBrandState = initialBrandState, action: ActionWithPayload<IBrandState>) => {
      const data = action.payload?.data
      const brand = action.payload?.brand
      const message = action.payload?.message
      const menuPriceCategory = action.payload?.menuPriceCategory
      const menuSalesChannel = action.payload?.menuSalesChannel
      const menuVariation = action.payload?.menuVariation
      const isLoading = action.payload?.isLoading

      switch (action.type) {
        case actionTypes.GetListBrand:
          return {...state, data, message}
        case actionTypes.GetBrandDetail:
          return {...state, brand, message}
        case actionTypes.IsLoading:
          return {...state, isLoading}
        case actionTypes.GetMenuPriceCategory:
          return {...state, menuPriceCategory}
        case actionTypes.GetMenuSalesChannel:
          return {...state, menuSalesChannel}
        case actionTypes.GetMenuVariation:
          return {...state, menuVariation}
        case actionTypes.SetSuccess:
          return {...state, message}
        default:
          return state
      }
    }
  )

  export const actions = {
    getListBrand: (data: any, message: string) => ({
      type: actionTypes.GetListBrand,
      payload: {data, message},
    }),
    getBrandDetail: (brand: any, message: string) => ({
      type: actionTypes.GetBrandDetail,
      payload: {brand, message},
    }),
    setIsLoading: (isLoading: boolean) => ({
      type: actionTypes.IsLoading,
      payload: {isLoading},
    }),
    getMenuPriceCategory: (menuPriceCategory: any, message: string) => ({
      type: actionTypes.GetMenuPriceCategory,
      payload: {menuPriceCategory, message},
    }),
    getMenuSalesChannel: (menuSalesChannel: any, message: string) => ({
      type: actionTypes.GetMenuSalesChannel,
      payload: {menuSalesChannel, message},
    }),
    getMenuVariation: (menuVariation: any, message: string) => ({
      type: actionTypes.GetMenuVariation,
      payload: {menuVariation, message},
    }),
    setSuccess: (message: string) => ({
      type: actionTypes.SetSuccess,
      payload: {message},
    }),
  }
}

export default BrandRedux
