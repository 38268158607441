import { Action } from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

namespace StoreRedux {
  const STORAGE_KEY = 'zeus-store'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    GetStoreDetail: '[Get Detail Store] Action',
    IsLoading: '[IsLoading] Action',
    SetSuccess: '[Set Success] Action',
    GetStockVariation: '[Get Menu Variation] Action',
  }

  const initialBrandState: IStoreState = {
    store: undefined,
    isLoading: undefined,
    message: undefined,
  }

  export interface IStoreState {
    store?: any
    isLoading?: boolean
    message?: string
    stockVariation?: any
  }

  export const reducer = persistReducer(
    {
      storage,
      key: STORAGE_KEY,
      whitelist: ['store', 'isLoading', 'message', 'stockVariation'],
    },
    (state: IStoreState = initialBrandState, action: ActionWithPayload<IStoreState>) => {
      const store = action.payload?.store
      const message = action.payload?.message
      const isLoading = action.payload?.isLoading
      const stockVariation = action.payload?.stockVariation

      switch (action.type) {
        case actionTypes.GetStoreDetail:
          return { ...state, store, message }
        case actionTypes.IsLoading:
          return { ...state, isLoading }
        case actionTypes.SetSuccess:
          return { ...state, message }
        case actionTypes.GetStockVariation:
          return { ...state, stockVariation }
        default:
          return state
      }
    }
  )

  export const actions = {
    getStoreDetail: (store: any, message: string) => ({
      type: actionTypes.GetStoreDetail,
      payload: { store, message },
    }),
    setIsLoading: (isLoading: boolean) => ({
      type: actionTypes.IsLoading,
      payload: { isLoading },
    }),
    setSuccess: (message: string) => ({
      type: actionTypes.SetSuccess,
      payload: { message }
    }),
    getStockVariation: (stockVariation: any, message: string) => ({
      type: actionTypes.GetStockVariation,
      payload: { stockVariation, message },
    }),
  }
}

export default StoreRedux
