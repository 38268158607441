import axios from 'axios'
import {BaseResponse} from 'src/app/models/api.types'
import {LegalData} from '../models/LegalData'

export const PRIVACY_POLICY = `api/v1/admins/settings/privacy-policy`
export const TERMS_OF_SERVICE = `api/v1/admins/settings/tos`

export function getPrivacyPolicy(cancelToken?: any) {
  return axios.get<BaseResponse<LegalData[]>>(PRIVACY_POLICY, {
    cancelToken,
  })
}

export function getTermsOfService(cancelToken?: any) {
  return axios.get<BaseResponse<LegalData[]>>(TERMS_OF_SERVICE, {
    cancelToken,
  })
}
