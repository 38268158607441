import axios, {AxiosRequestConfig, CancelToken} from 'axios'
import {UserModel} from '../models/UserModel'

export const GET_USER_BY_ACCESSTOKEN_URL = `/auth/get-user`
export const LOGIN_BY_PHONE = `/api/v1/merchants/login/phone-password`
export const LOGIN_VALIDATION_BY_PHONE = `/api/v1/merchants/login/phone-otp-validation`
export const LOGIN_BY_EMAIL = `/api/v1/merchants/login`
export const LOGIN_VALIDATION_BY_EMAIL = `/api/v1/merchants/login/email-otp-validation`
export const REGISTER_URL = `/auth/register`
export const REQUEST_PASSWORD_URL = `/api/v1/merchants/reset-password/phone`
export const SET_PASSWORD = `/api/v1/merchants/reset-password/password`
export const PROFILE = `/api/v1/merchants/profile`
export const UPDATE_PASSWORD = `/api/v1/merchants/profile/password`
export const VERIFY_EMAIL = `/api/v1/merchants/profile/verify-email`
export const VERIFY_PHONE = `/api/v1/merchants/profile/verify-phone`
export const VERIFY_OTP_EMAIL = `/api/v1/merchants/profile/verify-email-validation`
export const VERIFY_OTP_PHONE = `/api/v1/merchants/profile/verify-phone-validation`
export const PHONE_VERIFICATION = `/api/v1/merchants/verifications/phone`
export const EMAIL_VERIFICATION = `/api/v1/merchants/verifications/email`
export const RESEND_EMAIL_VERIFICATION = `/api/v1/merchants/profile/verify-email/resend`
export const CHECK_SHIFT = `/api/v1/orders/cashiers/shifts/current`
export const CASHIER_CHECKIN = `/api/v1/orders/cashiers/checkin`
export const CASHIER_CHECKOUT = `/api/v1/orders/cashiers/checkout`
export const VERIFY_LOGIN = `/api/v1/merchants/login/verify`
export const INCOME_RECAPS_PRINT = (cashier_shift_id: string) =>
  `/api/v1/orders/offline-orders/income/${cashier_shift_id}/print`
export const INCOME_RECAPS = (cashier_shift_id: string) =>
  `/api/v1/orders/offline-orders/income/${cashier_shift_id}`
export const LIST_MANAGER = (store_id: string) =>
  `/api/v1/merchants/stores/users/${store_id}/managers?statuses[]=ACTIVE`
export const BUSINESS_DATE = (store_id: string) =>
  `/api/v1/orders/pos/settings/${store_id}/end-of-day`

// Server should return AuthModel
export function loginByPhone(phone: string, password: string) {
  return axios.post(LOGIN_BY_PHONE, {phone, password})
}

export function loginValidationByPhone(phone: string, otp_code: string) {
  return axios.post(LOGIN_VALIDATION_BY_PHONE, {phone, otp_code})
}

export function loginByEmail(email: string, password: string, config?: AxiosRequestConfig) {
  return axios.post(LOGIN_BY_EMAIL, {email, password}, config)
}

export function loginValidationByEmail(email: string, otp_code: string) {
  return axios.post(LOGIN_VALIDATION_BY_EMAIL, {email, otp_code})
}
export function resendEmailVerification(email: string) {
  return axios.post(RESEND_EMAIL_VERIFICATION, {email})
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

export function requestPassword(phone: string) {
  return axios.post(REQUEST_PASSWORD_URL, {phone})
}

export function setPassword(token: string, password: string) {
  return axios.post(SET_PASSWORD, {password}, {params: {token: token}})
}

export function getUserProfile(token: string) {
  return axios.get(PROFILE, {
    headers: {
      Authorization: `${token}`,
    },
  })
}

export function getProfile() {
  return axios.get(PROFILE)
}

export function updateProfile(token: string, data: any) {
  return axios.put(PROFILE, data, {
    headers: {
      Authorization: `${token}`,
    },
  })
}

export function updatePassword(token: string, data: {old_password: string; new_password: string}) {
  return axios.put(UPDATE_PASSWORD, data, {
    headers: {
      Authorization: `${token}`,
    },
  })
}

export function verifyEmail(token: string, data: any) {
  return axios.post(VERIFY_EMAIL, data, {
    headers: {
      Authorization: `${token}`,
    },
  })
}

export function verifyPhone(token: string, data: any) {
  return axios.post(VERIFY_PHONE, data, {
    headers: {
      Authorization: `${token}`,
    },
  })
}

export function verifyOtpEmail(token: string, data: any) {
  return axios.post(VERIFY_OTP_EMAIL, data, {
    headers: {
      Authorization: `${token}`,
    },
  })
}

export function verifyOtpPhone(token: string, data: any) {
  return axios.post(VERIFY_OTP_PHONE, data, {
    headers: {
      Authorization: `${token}`,
    },
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function setPasswordVerification(token: string | null, password: string) {
  return axios.post(PHONE_VERIFICATION, {token, password})
}

export function setEmailVerification(token: string | null) {
  return axios.post(EMAIL_VERIFICATION, {token})
}

export function getCurrentShift(cancelToken?: CancelToken) {
  return axios.get(CHECK_SHIFT, {cancelToken})
}

export function getListManager(store_id: string, params: any = {}, cancelToken?: CancelToken) {
  return axios.get(LIST_MANAGER(store_id), {params, cancelToken})
}

export function setCashierCheckIn(manager_id: string, petty_cash: number) {
  return axios.post(CASHIER_CHECKIN, {manager_id, petty_cash})
}

export function setCashierCheckOut(manager_id: string) {
  return axios.post(CASHIER_CHECKOUT, {manager_id})
}

export function verifyLogin(user_id: string, password: string, cancelToken?: any) {
  return axios.post(VERIFY_LOGIN, {user_id, password}, {cancelToken})
}

export function getIncomeRecaps(cashier_shift_id: string) {
  return axios.get(INCOME_RECAPS(cashier_shift_id))
}

export function getIncomeRecapsPrint(cashier_shift_id: string) {
  return axios.get(INCOME_RECAPS_PRINT(cashier_shift_id))
}

export function getBusinessDate(store_id: string) {
  return axios.get(BUSINESS_DATE(store_id))
}
