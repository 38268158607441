import React, {createContext, useCallback, useContext, useEffect, useMemo} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from 'src/setup'
import {useSelectorContext} from '../hooks/selector-hook'
import {UserModel} from '../modules/auth/models/UserModel'
import TransactionRedux from '../modules/transaction/redux/TransactionRedux'
import {
  clearNotificationTags,
  listenClickNotification,
  listenNotification,
  listenPermission,
  setNotificationTags,
  unlistenClickNotification,
  unlistenNotification,
  unlistenPermission,
} from '../utils/notification-util'

export interface NotificationData {
  data: any
}

export interface NotificationContextModel {
  findNotification: <R>(
    listener: (data: NotificationData | undefined) => R | undefined
  ) => R | undefined
  setNotification: (data: NotificationData | undefined) => void
}

const NotificationContext = createContext<NotificationContextModel>({
  findNotification: (() => []) as any,
  setNotification: () => undefined,
})

export function useNotificationData() {
  return useContext(NotificationContext)
}

export function useFindNotification<R>(
  listener: (data: NotificationData | undefined) => R | undefined
): R | undefined {
  return useContext(NotificationContext).findNotification(listener)
}

export function useNotificationListener<R>(
  selector: (data: NotificationData | undefined) => R | undefined,
  listener: (data: R) => void
) {
  const data = useContext(NotificationContext).findNotification(selector)

  useEffect(() => (data ? listener(data) : undefined), [data])
}

const NotificationListener: React.FC = () => {
  const user = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {setNotification} = useNotificationData()
  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    user ? setNotificationTags(user) : clearNotificationTags()
  }, [user])

  const permissionHandler = useCallback(
    (allowed) => {
      if (allowed && user) setNotificationTags(user)
    },
    [user]
  )

  //Start::NOTIFICATION_HANDLER
  const handlePublicNotification = useCallback(
    (event) => {
      if (user && event?.data?.event?.startsWith('order.')) {
        dispatch(TransactionRedux.actions.countTransaction(user.store_id))
      }
    },
    [user]
  )

  const notificationHandler = useCallback(
    (event) => {
      handlePublicNotification(event)
      setNotification(event)
    },
    [setNotification, handlePublicNotification]
  )

  useEffect(() => {
    listenNotification(notificationHandler)
    return () => unlistenNotification(notificationHandler)
  }, [notificationHandler])

  const notificationClickHandler = useCallback(
    (_, data) => {
      console.log(data?.url)
      if (data?.url) history.push(new URL(data.url).pathname)
    },
    [history.push]
  )

  useEffect(() => {
    listenClickNotification(notificationClickHandler)
    return () => unlistenClickNotification(notificationClickHandler)
  }, [notificationClickHandler])
  //End::NOTIFICATION_HANDLER

  useEffect(() => {
    listenPermission(permissionHandler)
    return () => unlistenPermission(permissionHandler)
  }, [permissionHandler])
  return <></>
}

export const NotificationProvider: React.FC = ({children}) => {
  const [, findNotification, setNotification] = useSelectorContext<NotificationData | undefined>(
    undefined,
    false
  )

  const value: NotificationContextModel = useMemo(
    () => ({
      findNotification,
      setNotification,
    }),
    [findNotification, setNotification]
  )
  return (
    <NotificationContext.Provider value={value}>
      <NotificationListener />
      {children}
    </NotificationContext.Provider>
  )
}
