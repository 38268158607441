import {useCallback, useEffect, useMemo} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {AsideMenuItem} from 'src/_metronic/layout/components/aside/AsideMenuItem'
import {AsideMenuItemWithSub} from 'src/_metronic/layout/components/aside/AsideMenuItemWithSub'
import {PermissionQuery, usePermissions} from '../hooks/permissions-hook'
import {UserModel} from '../modules/auth/models/UserModel'
import BalanceScreens from '../modules/balance/Screens'
import BrandScreens from '../modules/brand/screens'
import StoreScreens from '../modules/store/Screens'
import TransactionRedux from '../modules/transaction/redux/TransactionRedux'
import TransactionScreens from '../modules/transaction/Screens'
import UserHermesScreens from '../modules/user-hermes/screens'

type Role = 'corporate' | 'brand' | 'store'

interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  permissions?: PermissionQuery
  role?: Role[]
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
}

const useMenus = (user?: any): Menu[] => {
  const dispatch = useDispatch()
  const {inprocess_count, preparing_count, cooking_count, ready_to_pickup_count, packaging_count} =
    useSelector<RootState>(({transaction}: any) => transaction, shallowEqual) as any
  useEffect(() => {
    if (user?.store?.id) dispatch(TransactionRedux.actions.countTransaction(user.store.id))
  }, [user])

  const assembler_count = useCallback(() => {
    const count = user?.store?.service_addons?.find(
      (item: any) => item.code === 'kitchen_display_pickup'
    )
      ? preparing_count + cooking_count
      : preparing_count + cooking_count + ready_to_pickup_count
    return count < 0 || isNaN(count) ? '0' : String(count)
  }, [preparing_count, cooking_count, ready_to_pickup_count, user])

  const pickup_count = useCallback(() => {
    const count = user?.store?.service_addons?.find(
      (item: any) => item.code === 'kitchen_display_assembler'
    )
      ? packaging_count + ready_to_pickup_count
      : preparing_count + packaging_count + ready_to_pickup_count
    return count < 0 || isNaN(count) ? '0' : String(count)
  }, [preparing_count, packaging_count, ready_to_pickup_count, user])

  const queueing_count = useCallback(() => {
    const count = preparing_count + cooking_count + packaging_count + ready_to_pickup_count
    return count < 0 || isNaN(count) ? '0' : String(count)
  }, [preparing_count, cooking_count, packaging_count, ready_to_pickup_count])

  return useMemo(
    () =>
      [
        {
          title: 'Dashboard',
          children: [
            {
              to: '/dashboard',
              icon: '/media/icons/efood/dashboard.svg',
              title: 'Dashboard',
            },
          ],
        },
        {
          title: 'KELOLA',
          children: [
            {
              to: '/brand',
              title: 'Brand',
              fontIcon: 'bi-archive',
              icon: '/media/icons/efood/IconSpoonFork.svg',
              children: [
                {
                  to: BrandScreens.LIST_BRAND.PATH,
                  title: 'List Brand',
                  hasBullet: true,
                  role: ['corporate'],
                  permissions: {code: 'kelola_list_brand'},
                },
                {
                  to: BrandScreens.LIST_BRAND_SUBMISSION.PATH,
                  title: 'List Pengajuan Brand',
                  hasBullet: true,
                  role: ['corporate'],
                  permissions: {code: 'kelola_list_pengajuan_brand'},
                },
                {
                  to: user?.merchant_id
                    ? BrandScreens.BRAND_DETAIL.buildPath(user?.merchant_id, 'data-brand')
                    : '#',
                  activePath: BrandScreens.LIST_BRAND.PATH,
                  title: 'Detail Brand',
                  hasBullet: true,
                  role: ['brand'],
                  permissions: {code: 'kelola_detail_brand'},
                },
              ],
            },
            {
              to: '/store',
              title: 'Store',
              icon: '/media/icons/efood/IconStore.svg',
              children: [
                {
                  to: user ? `/store/list/data-store/${user.store_id}/data-store` : '#',
                  activePath: '/store/list/',
                  title: 'Data Store',
                  hasBullet: true,
                  role: ['store'],
                  permissions: {code: 'kelola_detail_store'},
                },
                {
                  to: user?.store
                    ? StoreScreens.STORE_MENU_STOCK.buildPath(
                        user.store_id,
                        user?.store?.merchant_id!
                      )
                    : '#',
                  title: 'Stok Menu',
                  permissions: {code: 'kelola_stok_menu', access: 'read'},
                  hasBullet: true,
                  role: ['store'],
                },
                {
                  to: user?.store
                    ? StoreScreens.STORE_VARIATION_STOCK.buildPath(
                        user.store_id,
                        user?.store?.merchant_id!
                      )
                    : '#',
                  title: 'Stok Variasi',
                  permissions: {code: 'kelola_stok_variasi', access: 'read'},
                  hasBullet: true,
                  role: ['store'],
                },
                {
                  to: StoreScreens.STORE_LIST.PATH,
                  title: 'List Store',
                  hasBullet: true,
                  role: ['brand', 'corporate'],
                  permissions: {code: 'kelola_list_store'},
                },
                {
                  to: StoreScreens.STORE_SUBMISSION_LIST.PATH,
                  title: 'List Pengajuan Store',
                  hasBullet: true,
                  role: ['brand', 'corporate'],
                  permissions: {code: 'kelola_list_pengajuan_store'},
                },
              ],
            },
            {
              to: '/cashier',
              icon: '/media/icons/efood/CashierUser.svg',
              title: 'Kasir',
              fontIcon: 'bi-app-indicator',
              role: ['store'],
              permissions: {code: 'kelola_kasir', access: 'read'},
            },
            user?.store?.merchant?.is_pos_endofday_enabled
              ? {
                  to: '/end-of-day',
                  icon: '/media/icons/efood/BusinessTime.svg',
                  title: 'End Of Day',
                  fontIcon: 'bi-app-indicator',
                  role: ['store'],
                  permissions: {code: 'kelola_eod', access: 'update'},
                }
              : {},
            {
              to: TransactionScreens.ORDER_HISTORY.buildPath(
                user?.store?.merchant_id ?? '',
                user?.store?.id ?? ''
              ),
              title: 'Riwayat Order',
              hasBullet: false,
              role: ['store'],
              icon: '/media/icons/efood/IconHistory.svg',
              permissions: {code: 'transaksi_riwayat_order', access: 'read'},
            },
            {
              to: '/banner',
              icon: '/media/icons/efood/Picture.svg',
              title: 'Setup Banner',
              fontIcon: 'bi-app-indicator',
              role: ['brand', 'corporate'],
              permissions: {code: 'kelola_setup_banner', access: 'create'},
            },
            {
              to: '/bulk-upload-menu/list',
              title: 'Bulk Upload Menu',
              fontIcon: 'bi-layers',
              role: ['brand', 'corporate'],
              icon: '/media/icons/efood/IconBulkUploadMenu.svg',
              permissions: {code: 'kelola_bulk_upload_menu'},
            },
            {
              to: BalanceScreens.PENCAIRAN_SALDO.PATH,
              title: 'Pencairan Saldo',
              icon: '/media/icons/efood/IconPencairanSaldo.svg',
              activePath: BalanceScreens.SALDO.PATH,
              permissions: {code: 'kelola_pencairan_saldo'},
            },
          ],
        },
        {
          title: 'User',
          children: [
            {
              to: '/user-hermes/',
              title: 'User',
              fontIcon: 'bi-archive',
              icon: '/media/icons/efood/IconUserHermes.svg',
              children: [
                {
                  to: UserHermesScreens.LIST_USER_CORPORATE.PATH,
                  title: 'List User Corporate',
                  hasBullet: true,
                  role: ['corporate'],
                  permissions: {code: 'user_list_user_corporate'},
                },
                {
                  to: UserHermesScreens.LIST_SUBMISSION_USER_CORPORATE.PATH,
                  title: 'List Pengajuan User Corporate',
                  hasBullet: true,
                  role: ['corporate'],
                  permissions: {code: 'user_list_pengajuan_user_corporate'},
                },
                {
                  to: UserHermesScreens.USER_BRAND.PATH,
                  title: 'List User Brand',
                  hasBullet: true,
                  role: ['corporate'],
                  permissions: {code: 'user_list_user_brand'},
                },
                {
                  to: UserHermesScreens.USER_STORE_LIST.PATH,
                  title: 'List User Store',
                  hasBullet: true,
                  role: ['brand', 'corporate'],
                  permissions: {code: 'user_list_user_store'},
                },
              ],
            },
          ],
        },
        {
          title: 'Transaksi',
          children: [
            {
              to: TransactionScreens.ORDER_EFOOD.buildPath(),
              title: 'Order eFOOD',
              icon: '/media/icons/efood/IconOrder.svg',
              badge: inprocess_count ? String(inprocess_count) : undefined,
              badgeCircle: true,
              role: ['store'],
              permissions: {code: 'transaksi_order_efood'},
            },
            {
              to: TransactionScreens.ORDER_POS.PATH,
              title: 'POS',
              icon: '/media/icons/efood/IconEkasir.svg',
              hidden: !user?.store?.service_addons?.find((item: any) => item.code === 'pos'),
              role: ['store'],
              permissions: {code: 'transaksi_pos'},
            },
            {
              to: TransactionScreens.ORDER_PRINTER_SETTING.PATH,
              title: 'Printer Setting',
              icon: '/media/icons/efood/IconEkasir.svg',
              hidden: !window.eApi?.print,
              role: ['store'],
            },
            {
              to: TransactionScreens.KICTHEN_DISPLAY_ASSEMBLER.buildPath(user?.store?.id ?? ''),
              title: 'Kitchen Display Assembler',
              hasBullet: false,
              role: ['store'],
              badge: assembler_count(),
              badgeCircle: true,
              icon: '/media/icons/efood/IconCashier.svg',
              hidden: !user?.store?.service_addons?.find(
                (item: any) => item.code === 'kitchen_display_assembler'
              ),
              permissions: {code: 'transaksi_kd_assembler'},
            },
            {
              to: TransactionScreens.KICTHEN_DISPLAY_PICKUP.buildPath(user?.store?.id ?? ''),
              title: 'Kitchen Display Pickup',
              hasBullet: false,
              role: ['store'],
              badge: pickup_count(),
              badgeCircle: true,
              icon: '/media/icons/efood/IconCart.svg',
              hidden: !user?.store?.service_addons?.find(
                (item: any) => item.code === 'kitchen_display_pickup'
              ),
              permissions: {code: 'transaksi_kd_pickup'},
            },
            {
              to: TransactionScreens.QUEUEING_DISPLAY.buildPath(user?.store?.id ?? ''),
              title: 'Queueing Display',
              hasBullet: false,
              role: ['store'],
              badge: queueing_count(),
              badgeCircle: true,
              icon: '/media/icons/efood/IconList.svg',
              hidden: !user?.store?.service_addons?.find(
                (item: any) => item.code === 'queueing_display'
              ),
              permissions: {code: 'transaksi_queueing_display'},
            },
          ],
        },
      ] as Menu[],
    [user, inprocess_count, preparing_count, cooking_count, ready_to_pickup_count, packaging_count]
  )
}

const GeneratedMenu: React.FC<{menu: Menu}> = ({menu}) => {
  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{menu.title}</span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
        badge={menu.badge}
        badgeCircle={menu.badgeCircle}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const hasRole = (currentRole: Role, roles?: Role[]) => !roles || roles.indexOf(currentRole) > -1

const AsideMenuData: React.FC = () => {
  const {hasAccess} = usePermissions()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const role: Role = useMemo(
    () => (user?.group ? 'corporate' : user?.merchant ? 'brand' : 'store'),
    [user]
  )
  const menus = useMenus(user)
  const generated = useMemo(
    () =>
      filterMenus(
        menus,
        (menu) => hasRole(role, menu.role) && hasAccess(menu.permissions) && !Boolean(menu.hidden)
      ),
    [hasAccess, menus]
  )
  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
