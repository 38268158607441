const TransactionScreens = {
  ORDER_EFOOD: {
    PATH: '/transaction/order/:tab',
    buildPath: (tab = 'inprocess') => `/transaction/order/${tab}`,
  },
  ORDER_POS: {
    PATH: '/transaction/pos',
  },
  ORDER_POS_ADD_ORDER: {
    PATH: '/transaction/pos/create/:channelId',
    buildPath: (channelId: string) => `/transaction/pos/create/${channelId}`,
  },
  ORDER_POS_CHECKOUT_ORDER: {
    PATH: '/transaction/pos/create/:channelId/checkout',
    buildPath: (channelId: string) => `/transaction/pos/create/${channelId}/checkout`,
  },
  ORDER_POS_HOLD_ORDER: {
    PATH: '/transaction/pos/hold/:channelId',
    buildPath: (channelId: string) => `/transaction/pos/hold/${channelId}`,
  },
  KICTHEN_DISPLAY_ASSEMBLER: {
    PATH: '/transaction/kitchen-display-assembler/:store_id',
    buildPath: (store_id: string) => `/transaction/kitchen-display-assembler/${store_id}`,
  },
  KICTHEN_DISPLAY_PICKUP: {
    PATH: '/transaction/kitchen-display-pickup/:store_id',
    buildPath: (store_id: string) => `/transaction/kitchen-display-pickup/${store_id}`,
  },
  QUEUEING_DISPLAY: {
    PATH: '/transaction/queueing-display/:store_id',
    buildPath: (store_id: string) => `/transaction/queueing-display/${store_id}`,
  },
  ORDER_HISTORY: {
    PATH: '/transaction/history/:merchant_id/:store_id',
    buildPath: (merchant_id: string, store_id: string) =>
      `/transaction/history/${merchant_id}/${store_id}`,
  },
  DETAIL_ORDER_HISTORY: {
    PATH: '/transaction/history/:merchant_id/:store_id/detail/:order_id',
    buildPath: (merchant_id: string, store_id: string, order_id: string) =>
      `/transaction/history/${merchant_id}/${store_id}/detail/${order_id}`,
  },
  ORDER_PRINTER_SETTING: {
    PATH: '/transaction/setting/printer',
  },
}

export const OrderTabs = {
  ORDER_INPROCESS: {
    PATH: TransactionScreens.ORDER_EFOOD.buildPath('inprocess'),
  },
  ORDER_COMPLETED: {
    PATH: TransactionScreens.ORDER_EFOOD.buildPath('completed'),
  },
  ORDER_CANCELLED: {
    PATH: TransactionScreens.ORDER_EFOOD.buildPath('cancelled'),
  },
}

export default TransactionScreens
