import React, {useState, MouseEventHandler, useEffect} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from 'src/_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import NumberFormat from 'react-number-format'
import css from 'src/scss/auth/components/input.module.scss'
import {verifyPhone, verifyOtpPhone} from 'src/app/modules/auth/redux/AuthCRUD'
import {UserModel} from 'src/app/modules/auth/models/UserModel'

interface UpdatePhoneProps {
  show: boolean
  handleClose: MouseEventHandler<HTMLDivElement | HTMLButtonElement> | any
  handleSuccess: Function
  handleError: Function
}

const initPhone = {
  current: '',
  new: '',
}

const phoneSchema = Yup.object().shape({
  new: Yup.string().min(5, 'Kolom wajib diisi').required('Kolom wajib diisi'),
})

const initVerify = {
  otp: '',
}

const verifySchema = Yup.object().shape({
  otp: Yup.string().min(4, 'OTP minimal 4 karakter').required('OTP wajib diisi'),
})

const UpdatePhone = ({show, handleClose, handleSuccess, handleError}: UpdatePhoneProps) => {
  // States
  const [loading, setLoading] = useState<boolean>(false)
  const [verify, setVerify] = useState<boolean>(false)

  // Variables
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string
  const user = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const phoneFormik = useFormik({
    initialValues: initPhone,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: phoneSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          await verifyPhone(`Bearer ${accessToken}`, {phone: `62${values.new}`})
          setVerify(true)
          setLoading(false)
          setSubmitting(false)
        } catch (_) {
          setLoading(false)
          setSubmitting(false)
          setStatus('No. HP gagal diubah.')
        }
      }, 1000)
    },
  })
  const verifyFormik = useFormik({
    initialValues: initVerify,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: verifySchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          await verifyOtpPhone(`Bearer ${accessToken}`, {
            phone: `62${phoneFormik?.values?.new}`,
            otp_code: values?.otp,
          })
          setVerify(false)
          setLoading(false)
          setSubmitting(false)
          handleClose()
          handleSuccess('No. HP berhasil diubah.')
        } catch (_) {
          setLoading(false)
          setSubmitting(false)
          setStatus('Verifikasi OTP gagal.')
        }
      }, 1000)
    },
  })

  // Hooks
  useEffect(() => {
    if (user) {
      const {phone} = user
      let resPhone
      if (phone[0] === '0') resPhone = phone.replace('0', '')
      if (String(phone).slice(0, 2) === '62') resPhone = String(phone).slice(2)
      if (resPhone) initPhone.current = resPhone
    }
  }, [user])

  useEffect(() => {
    const {values, setFieldValue} = phoneFormik
    if (values.new[0] === '0') setFieldValue('new', '')
  }, [phoneFormik])

  useEffect(() => {
    if (phoneFormik?.status) handleError(phoneFormik?.status)
    if (verifyFormik?.status) handleError(verifyFormik?.status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneFormik?.status, verifyFormik?.status])

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={handleClose}
      centered
    >
      <div className='modal-header pt-8 pb-5 px-10 d-flex justify-content-between border-bottom-1'>
        {/* begin::Close */}
        <h1>{verify ? 'Verifikasi Ubah Data' : 'Ubah No. HP'}</h1>
        <div className='btn btn-icon btn-sm btn-transparent' onClick={handleClose}>
          <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>
      <div>
        <div className='modal-body px-10 py-10'>
          {verify ? (
            <form className='form w-100' onSubmit={verifyFormik.handleSubmit} noValidate>
              <p>Kami telah mengirim 4-digit OTP ke No. HP baru Anda.</p>
              <div className='fv-row'>
                <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>
                  Masukan OTP
                </label>
                <input
                  placeholder=''
                  {...verifyFormik.getFieldProps('otp')}
                  className='form-control form-control-lg form-control-solid'
                  type='text'
                  name='otp'
                  autoComplete='off'
                />
                {verifyFormik.touched.otp && verifyFormik.errors.otp && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{verifyFormik.errors.otp}</span>
                  </div>
                )}
              </div>
            </form>
          ) : (
            <form className='form w-100' onSubmit={phoneFormik.handleSubmit} noValidate>
              <div className='fv-row mb-10'>
                <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>
                  No. HP Saat Ini
                </label>
                <div className={css.input_prefix}>
                  <span style={{fontSize: 15, color: '#7F8299'}}>+62</span>
                  <input
                    placeholder=''
                    {...phoneFormik.getFieldProps('current')}
                    className='form-control form-control-lg'
                    style={{backgroundColor: '#E3E6EF', color: '#7F8299'}}
                    type='text'
                    name='current'
                    autoComplete='off'
                    disabled
                  />
                </div>
              </div>

              <div className='fv-row'>
                <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>
                  No. HP Baru*
                </label>
                <div className={css.input_prefix}>
                  <span style={{fontSize: 15}}>+62</span>
                  <NumberFormat
                    placeholder=''
                    {...phoneFormik.getFieldProps('new')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': phoneFormik.touched.new && phoneFormik.errors.new},
                      css.inputPrefix
                    )}
                    type='tel'
                    name='new'
                    autoComplete='off'
                    fixedDecimalScale={true}
                    decimalScale={0}
                    allowNegative={false}
                  />
                </div>
                {phoneFormik.touched.new && phoneFormik.errors.new && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{phoneFormik.errors.new}</span>
                  </div>
                )}
              </div>
            </form>
          )}
        </div>

        <div className='modal-footer'>
          {verify ? (
            <div className='d-flex pb-lg-0'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className={`btn btn-lg btn-light fw-bolder d-block me-4 ${
                  verifyFormik.isSubmitting ? 'disabled' : ''
                }`}
                onClick={() => setVerify(false)}
              >
                Kembali
              </button>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-secondary fw-bolder'
                onClick={() => verifyFormik.handleSubmit()}
                disabled={verifyFormik.isSubmitting}
              >
                <span className='indicator-label'>Verifikasi</span>
                {loading && (
                  <span className='indicator-progress'>
                    Mohon Tunggu...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          ) : (
            <div className='d-flex pb-lg-0'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className={`btn btn-lg btn-light fw-bolder d-block me-4 ${
                  phoneFormik.isSubmitting ? 'disabled' : ''
                }`}
                onClick={handleClose}
              >
                Kembali
              </button>
              <button
                type='button'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-secondary fw-bolder'
                onClick={() => phoneFormik.handleSubmit()}
                disabled={phoneFormik.isSubmitting}
              >
                <span className='indicator-label'>Ubah No. HP</span>
                {loading && (
                  <span className='indicator-progress'>
                    Mohon Tunggu...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default UpdatePhone
