/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {loginByPhone} from '../redux/AuthCRUD'
import AuthRedux from '../redux/AuthRedux'
import AuthScreens from '../screens'
import {useDispatch} from 'react-redux'
import InlineSVG from 'react-inlinesvg/esm'
import {getTitle} from 'src/app/utils/title-utils'
import NumberFormat from 'react-number-format'
import css from 'src/scss/auth/components/input.module.scss'
import LegalScreens from '../../legal/Screens'

const loginSchema = Yup.object().shape({
  phone: Yup.string().min(5, 'Kolom wajib diisi').required('Kolom wajib diisi'),
  password: Yup.string().min(6, 'Password minimal 6 karakter').required('Kolom wajib diisi'),
})

const initialValues = {
  email: '',
  password: '',
  phone: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function LoginPhone() {
  // States
  const [loading, setLoading] = useState(false)

  // Variables
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          const result = await loginByPhone(`62${values.phone}`, values.password)
          const {
            data: {
              data: {token, refreshtoken},
            },
          } = result

          setLoading(false)
          setSubmitting(false)
          dispatch(AuthRedux.actions.login(token, refreshtoken))
        } catch (e: any) {
          const {
            response: {
              data: {message},
            },
          } = e
          if (
            message[0].constraint[0].code &&
            message[0].constraint[0].code === 'PHONE_NOT_VERIFIED'
          ) {
            setStatus('No. HP Anda belum terverifikasi')
          } else if (
            message[0].constraint[0].code &&
            message[0].constraint[0].code === 'USER_NOT_VERIFIED'
          ) {
            setStatus('User Anda belum terverifikasi')
          } else if (
            message[0].constraint[0].code &&
            message[0].constraint[0].code === 'UNREGISTERED_PHONE'
          ) {
            setStatus(message[0].constraint[0].message)
          } else {
            setStatus('No. HP dan/atau password tidak sesuai.')
          }
          setLoading(false)
          setSubmitting(false)
        }
      }, 1000)
    },
  })

  // Hooks
  useEffect(() => {
    document.title = getTitle('Login')
  }, [])

  useEffect(() => {
    if (formik.values.phone[0] === '0') {
      formik.setFieldValue('phone', '')
    }
  }, [formik, formik.values.phone])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3 fs-2'>Login</h1>
      </div>
      {/* begin::Heading */}
      {formik.status ? (
        <div
          className='d-flex align-items-center rounded py-4 px-5 mb-10'
          style={{backgroundColor: 'rgba(240, 66, 108, 0.1)'}}
        >
          <InlineSVG src={'/media/icons/efood/IconWarning.svg'} style={{marginRight: 16}} />
          <div className='flex-fill text-danger'>{formik.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>No. HP</label>
        <div className={css.input_prefix}>
          <span>+62</span>
          <NumberFormat
            placeholder=''
            {...formik.getFieldProps('phone')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.phone && formik.errors.phone},
              css.inputPrefix
            )}
            type='tel'
            name='phone'
            autoComplete='off'
            fixedDecimalScale={true}
            decimalScale={0}
            allowNegative={false}
            tabIndex={1}
          />
        </div>
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container text-danger'>
            <span role='alert'>{formik.errors.phone}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <div className='d-flex'>
          <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>Password</label>
          <Link to={AuthScreens.FORGOT.PATH} className='fs-6 fw-bolder link-info' tabIndex={5}>
            Lupa Password
          </Link>
        </div>
        <input
          placeholder=''
          {...formik.getFieldProps('password')}
          className={clsx('form-control form-control-lg form-control-solid', {
            'is-invalid': formik.touched.password && formik.errors.password,
          })}
          type='password'
          name='password'
          autoComplete='off'
          tabIndex={2}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container text-danger'>
            <span role='alert'>{formik.errors.password}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-secondary w-100 mb-5'
          disabled={formik.isSubmitting}
          tabIndex={3}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Mohon Tunggu...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <div className='mt-2 text-center'>
        <Link
          to={AuthScreens.LOGIN_EMAIL.PATH}
          className='fs-6 fw-bolder link-info text-secondary'
          tabIndex={4}
        >
          Login dengan email
        </Link>
      </div>
      <div className='mt-4 text-center'>
        Dengan login, Anda telah menyetujui{' '}
        <Link to={LegalScreens.PRIVACY_POLICY.PATH} className='link-info fw-bolder'>
          Kebijakan Privasi
        </Link>{' '}
        dan{' '}
        <Link to={LegalScreens.TERMS.PATH} className='link-info fw-bolder'>
          Ketentuan Layanan
        </Link>{' '}
        yang berlaku.
      </div>
      {/* end::Action */}
    </form>
  )
}
