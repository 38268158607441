const BalanceScreens = {
  SALDO: {
    PATH: '/balance',
  },
  PENCAIRAN_SALDO: {
    PATH: '/balance/pencairan-saldo',
    TITLE: 'List Pencairan Saldo',
    TITLE_ALT: 'Pencairan Saldo',
  },
  INFORMASI_SALDO: {
    PATH: '/balance/informasi-saldo',
    TITLE: 'List Informasi Saldo',
    TITLE_ALT: 'Informasi Saldo',
  },
  PENCAIRAN_SALDO_DETAIL: {
    PATH: '/balance/pencairan-saldo/:id',
    TITLE: 'Detail Pencairan Saldo',
    buildPath: (id?: string) => `/balance/pencairan-saldo/${id}`,
  },
  INFORMASI_SALDO_DETAIL: {
    PATH: '/balance/informasi-saldo/:id',
    TITLE: 'Detail Informasi Saldo',
    buildPath: (id?: string) => `/balance/informasi-saldo/${id}`,
  },
}

export default BalanceScreens
