const StoreScreens = {
  STORE_LIST: {
    PATH: '/store/list',
  },
  STORE_SUBMISSION_LIST: {
    PATH: '/store/submission-list',
  },
  STORE_SUBMISSION_DETAIL: {
    PATH: '/store/submission-list/:type/:id/:tab',
    buildPath: (type: string, id: string, tab: string) =>
      `/store/submission-list/${type}/${id}/${tab}`,
  },
  STORE_REGISTER: {
    PATH: '/store/list/register/:id',
    buildPath: (id: string) => `/store/list/register/${id}`,
  },
  DATA_STORE: {
    PATH: '/store/list/data-store/:id/:tab',
    buildPath: (id: string, tab: string) => `/store/list/data-store/${id}/${tab}`,
  },
  STORE_DETAIL: {
    PATH: '/store/list/detail/:id/:tab',
    buildPath: (id: string, tab: string) => `/store/list/detail/${id}/${tab}`,
  },
  STORE_SALES_AND_CATEGORY: {
    PATH: '/store/list/sales-and-price/:id/:merchantId',
    buildPath: (id: string, merchantId: string) =>
      `/store/list/sales-and-price/${id}/${merchantId}`,
  },
  CATEGORY_MENU_LIST: {
    PATH: '/store/list/category-menu-list/:id/:merchantId',
    buildPath: (id: string, merchantId: string) =>
      `/store/list/category-menu-list/${id}/${merchantId}`,
  },
  ADD_MENU: {
    PATH: '/store/list/category-menu/:id/:merchantId/:channelId/add-menu',
    buildPath: (id: string, merchantId: string, channelId: string) =>
      `/store/list/category-menu/${id}/${merchantId}/${channelId}/add-menu`,
  },
  LIST_MENU: {
    PATH: '/store/list/category-menu/:id/:merchantId/:channelId/menu',
    buildPath: (id: string, merchantId: string, channelId: string) =>
      `/store/list/category-menu/${id}/${merchantId}/${channelId}/menu`,
  },
  UPDATE_MENU: {
    PATH: '/store/list/category-menu/:id/:merchantId/:channelId/menu/:menuId',
    buildPath: (id: string, merchantId: string, channelId: string, menuId: string) =>
      `/store/list/category-menu/${id}/${merchantId}/${channelId}/menu/${menuId}`,
  },
  STORE_STOCK_MANAGEMENT: {
    PATH: '/store/list/:id/manage-stock/:merchantId/:tab',
    buildPath: (id: string, merchantId: string, tab: string) =>
      `/store/list/${id}/manage-stock/${merchantId}/${tab}`,
  },
  STORE_MENU_STOCK: {
    PATH: '/store/menu-stock/:id/:merchantId',
    buildPath: (id: string, merchantId: string) => `/store/menu-stock/${id}/${merchantId}`,
  },
  STORE_VARIATION_STOCK: {
    PATH: '/store/variation-stock/:id/:merchantId',
    buildPath: (id: string, merchantId: string) => `/store/variation-stock/${id}/${merchantId}`,
  },
  STORE_DISCOUNT_LIST: {
    PATH: '/store/list/discount/:id/:merchantId',
    buildPath: (id: string, merchantId: string) => `/store/list/discount/${id}/${merchantId}`,
    TITLE: 'List Diskon Menu',
    TITLE_ALT: 'Diskon Menu',
  },
  STORE_DISCOUNT_CREATE: {
    PATH: '/store/list/discount/:id/:merchantId/create',
    buildPath: (id: string, merchantId: string) =>
      `/store/list/discount/${id}/${merchantId}/create`,
    TITLE: 'Tambah Diskon Menu',
  },
  STORE_DISCOUNT_DETAIL: {
    PATH: '/store/list/discount/:id/:merchantId/detail/:discountId',
    buildPath: (id: string, merchantId: string, discountId?: string) =>
      `/store/list/discount/${id}/${merchantId}/detail/${discountId}`,
    TITLE: 'Detail Diskon Menu',
  },
  BRAND_PROMO_VIEW: {
    PATH: '/store/list/data-store/:id/promo/:merchantId/brand/:discountId',
    buildPath: (id?: string, merchantId?: string, discountId?: string) =>
      `/store/list/data-store/${id}/promo/${merchantId}/brand/${discountId}`,
    TITLE: 'Detail Promo Brand',
  },
  MENU_PROMO_VIEW: {
    PATH: '/store/list/data-store/:id/promo/:merchantId/menu/:discountId',
    buildPath: (id?: string, merchantId?: string, discountId?: string) =>
      `/store/list/data-store/${id}/promo/${merchantId}/menu/${discountId}`,
    TITLE: 'Detail Diskon Menu',
  },
}

export default StoreScreens
