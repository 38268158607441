/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {Link} from 'react-router-dom'
import LogoutModal from 'src/app/components/LogoutModal'
import {UserModel} from 'src/app/modules/auth/models/UserModel'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import UserScreens from 'src/app/modules/user/screens'
import {RootState} from '../../../../setup'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const history = useHistory()
  const checkIn = useSelector<RootState>(({auth}: any) => auth.checkIn, shallowEqual) as boolean
  const dispatch = useDispatch()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      id='MyNavbarDropdown'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {/* <img alt='Logo' src={toAbsoluteUrl(`/media/avatar/150-1.jpg`)} /> */}
            <span
              className='py-3 px-5 rounded'
              style={{backgroundColor: 'rgba(39, 192, 205, 0.2)'}}
            >
              <span className='fw-bolder' style={{color: '#27C0CD'}}>
                {user?.name[0]}
              </span>
            </span>
          </div>

          <div className='d-flex flex-column' style={{overflow: 'hidden'}}>
            <div
              className='fw-bolder align-items-center fs-5'
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {user?.name}
            </div>
            <div
              className='fw-normal text-muted align-items-center fs-6'
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {user?.email}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-3 my-1'>
        <Link to={UserScreens.PROFILE.PATH} className='menu-link px-5'>
          Profil Saya
        </Link>
      </div>

      {!user?.store_id && (
        <div className='menu-item px-3 my-1'>
          <Link to={UserScreens.CHANGE_PASSWORD.PATH} className='menu-link px-5'>
            Ubah Password
          </Link>
        </div>
      )}

      <div className='menu-item px-3'>
        <button
          onClick={() => {
            if (checkIn) {
              const element = document.querySelector('#MyNavbarDropdown')
              element?.classList.remove('show')
              dispatch(AuthRedux.actions.setModalCheckOut(true))
            } else {
              setShowLogoutModal(true)
            }
          }}
          className='px-5 ms-5 btn'
          style={{backgroundColor: 'rgba(240, 66, 108, 0.1)'}}
        >
          <span style={{color: 'rgba(240, 66, 108)'}}>{checkIn ? 'Check Out' : 'Logout'}</span>
        </button>
      </div>
      <LogoutModal
        show={showLogoutModal}
        handleClose={() => setShowLogoutModal(false)}
        handleContinue={() => history.push('/logout')}
      />
    </div>
  )
}

export {HeaderUserMenu}
