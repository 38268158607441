import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

namespace PrinterRedux {
  const STORAGE_KEY = 'tx-printer'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    SetPrinterConfig: '[Set Printer Config] Action',
    SetPrinterConfigLocal: '[Set Printer Config Local] Action',
  }

  const initialState: IStoreState = {
    printer_name: undefined,
    width: undefined,
  }

  export interface IStoreState {
    printer_name?: string
    width?: number
  }

  export interface IStorePayload extends IStoreState {}

  export const reducer = persistReducer(
    {
      storage,
      key: STORAGE_KEY,
      whitelist: ['printer_name', 'width'],
    },
    (state: IStoreState = initialState, action: ActionWithPayload<IStorePayload> = {type: ''}) => {
      const {printer_name, width} = action.payload ?? {}

      if (action.type === actionTypes.SetPrinterConfig) {
        return {
          ...state,
          printer_name,
          width,
        }
      }
      return state
    }
  )

  export const actions = {
    setPrinterConfig: (printer_name?: string, width?: number) => ({
      type: actionTypes.SetPrinterConfig,
      payload: {
        printer_name,
        width,
      },
    }),
  }
}

export default PrinterRedux
