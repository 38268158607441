import OneSignal from 'react-onesignal'

const setupNotification = () => {
  const appId = process.env.REACT_APP_ONESIGNAL_ID
  const allowLocalhostAsSecureOrigin = process.env.REACT_APP_ONESIGNAL_LOCALHOST === 'true'
  if (appId) {
    if (window.eApi?.version) window.eApi?.setupNotification()
    else
      OneSignal.init({
        appId,
        allowLocalhostAsSecureOrigin,
        welcomeNotification: {
          disable: true,
        },
      }).then(() => {
        OneSignal.showNativePrompt()
      })
  }
}

export default setupNotification
