import React, {useState, MouseEventHandler, useEffect} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from 'src/_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {verifyEmail, verifyOtpEmail} from 'src/app/modules/auth/redux/AuthCRUD'
import {UserModel} from 'src/app/modules/auth/models/UserModel'

interface UpdateEmailProps {
  show: boolean
  handleClose: MouseEventHandler<HTMLDivElement | HTMLButtonElement> | any
  handleSuccess: Function
  handleError: Function
}

const initEmail = {
  current: '',
  new: '',
}

const emailSchema = Yup.object().shape({
  new: Yup.string().email().required('Email Baru wajib diisi'),
})

const initVerify = {
  otp: '',
}

const verifySchema = Yup.object().shape({
  otp: Yup.string().min(4, 'OTP minimal 4 karakter').required('OTP wajib diisi'),
})

const UpdateEmail = ({show, handleClose, handleSuccess, handleError}: UpdateEmailProps) => {
  // States
  const [loading, setLoading] = useState<boolean>(false)
  const [verify, setVerify] = useState<boolean>(false)

  // Variables
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string
  const user = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const emailFormik = useFormik({
    initialValues: initEmail,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: emailSchema,
    onSubmit: (values, {setStatus, setSubmitting, setErrors}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          await verifyEmail(`Bearer ${accessToken}`, {email: values?.new})
          setVerify(true)
          setLoading(false)
          setSubmitting(false)
          handleSuccess('Email verifikasi berhasil dikirim')
        } catch (e: any) {
          setLoading(false)
          setSubmitting(false)
          setStatus(e?.response?.data?.message[0]?.constraint[0]?.message)
          setErrors({new: e?.response?.data?.message[0]?.constraint[0]?.message})
        }
      }, 1000)
    },
  })
  const verifyFormik = useFormik({
    initialValues: initVerify,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: verifySchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          await verifyOtpEmail(`Bearer ${accessToken}`, {
            email: emailFormik?.values?.new,
            otp_code: values?.otp,
          })
          setVerify(false)
          setLoading(false)
          setSubmitting(false)
          handleSuccess('Email berhasil diubah.')
          handleClose()
        } catch (_) {
          setLoading(false)
          setSubmitting(false)
          setStatus('Verifikasi OTP gagal')
        }
      }, 1000)
    },
  })

  // Hooks
  useEffect(() => {
    if (user) {
      if (user?.email) initEmail.current = user?.email
    }
  }, [user])

  useEffect(() => {
    if (emailFormik?.status) handleError(emailFormik?.status)
    if (verifyFormik?.status) handleError(verifyFormik?.status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailFormik?.status, verifyFormik?.status])

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={handleClose}
      centered
    >
      <div className='modal-header pt-8 pb-5 px-10 d-flex justify-content-between border-bottom-1'>
        {/* begin::Close */}
        <h1>{verify ? 'Verifikasi Ubah Data' : 'Ubah Email'}</h1>
        <div className='btn btn-icon btn-sm btn-transparent' onClick={handleClose}>
          <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>
      <div>
        <div className='modal-body px-10 py-10'>
          {verify ? (
            <p>
              Kami telah mengirim link verifikasi ke email baru Anda. Silahkan cek email dan klik
              link untuk melakukan verifikasi ubah email.
            </p>
          ) : (
            <form className='form w-100' onSubmit={emailFormik.handleSubmit} noValidate>
              <div className='fv-row mb-10'>
                <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>
                  Email Saat Ini
                </label>
                <input
                  placeholder=''
                  {...emailFormik.getFieldProps('current')}
                  className='form-control form-control-lg'
                  style={{backgroundColor: '#E3E6EF', color: '#7F8299'}}
                  type='text'
                  name='current'
                  autoComplete='off'
                  disabled
                />
              </div>

              <div className='fv-row'>
                <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>
                  Email Baru*
                </label>
                <input
                  placeholder=''
                  {...emailFormik.getFieldProps('new')}
                  className={clsx('form-control form-control-lg form-control-solid', {
                    'is-invalid': emailFormik.touched.new && emailFormik.errors.new,
                  })}
                  type='text'
                  name='new'
                  autoComplete='off'
                />
                {emailFormik.touched.new && emailFormik.errors.new && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{emailFormik.errors.new}</span>
                  </div>
                )}
              </div>
            </form>
          )}
        </div>

        <div className='modal-footer'>
          {verify ? (
            <div className='d-flex pb-lg-0'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className={`btn btn-lg btn-secondary fw-bolder`}
                onClick={() => {
                  handleClose()
                  setVerify(false)
                }}
              >
                Okay
              </button>
            </div>
          ) : (
            <div className='d-flex pb-lg-0'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className={`btn btn-lg btn-light fw-bolder d-block me-4 ${
                  emailFormik.isSubmitting ? 'disabled' : ''
                }`}
                onClick={handleClose}
              >
                Kembali
              </button>
              <button
                type='button'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-secondary fw-bolder'
                onClick={() => emailFormik.handleSubmit()}
                disabled={emailFormik.isSubmitting}
              >
                <span className='indicator-label'>Ubah Email</span>
                {loading && (
                  <span className='indicator-progress'>
                    Mohon Tunggu...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default UpdateEmail
