import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import BrandRedux from 'src/app/modules/brand/redux/BrandRedux'
import StoreRedux from 'src/app/modules/store/redux/StoreRedux'
import QueriesRedux from 'src/app/modules/queries/redux/QueriesRedux'
import UserHermesRedux from 'src/app/modules/user-hermes/redux/UserRedux'
import TransactionRedux from 'src/app/modules/transaction/redux/TransactionRedux'
import CashierRedux from 'src/app/modules/cashier/redux/CashierRedux'
import PrinterRedux from 'src/app/modules/transaction/redux/PrinterRedux'

export const rootReducer = combineReducers({
  auth: AuthRedux.reducer,
  brand: BrandRedux.reducer,
  cashier: CashierRedux.reducer,
  store: StoreRedux.reducer,
  queries: QueriesRedux.reducer,
  userHermes: UserHermesRedux.reducer,
  transaction: TransactionRedux.reducer,
  printer: PrinterRedux.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([AuthRedux.saga(), TransactionRedux.saga()])
}
