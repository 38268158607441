import React, {Suspense, lazy, Component, useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Redirect, Route, RouteProps, Switch} from 'react-router-dom'
import {RootState} from 'src/setup'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/Dashboard'
import {UserModel} from '../modules/auth/models/UserModel'
import ModalCheckIn from '../components/ModalCheckIn'
import ModalAlertCheckout from '../components/ModalAlertCheckout'
import ModalCheckOut from '../components/ModalCheckOut'

type Role = 'corporate' | 'brand' | 'store'

interface ProtectedRouteProps<Path extends string = string> extends RouteProps<Path> {
  roles: Role[]
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({roles, ...props}) => {
  const user = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const role = useMemo(() => {
    if (user?.group) return 'corporate'
    else if (user?.merchant) return 'brand'
    else return 'store'
  }, [user])
  return (
    <Route
      {...props}
      render={(renderProps) =>
        roles.includes(role) ? <Component {...renderProps} /> : <Redirect to='/login' />
      }
    />
  )
}

export function PrivateRoutes() {
  const MenuPage = lazy(() => import('../modules/menu/MenuPage'))
  const UserPage = lazy(() => import('../modules/user/UserPage'))
  const BrandPage = lazy(() => import('../modules/brand/BrandPage'))
  const StorePage = lazy(() => import('../modules/store/StoreRoutes'))
  const UserHermesPage = lazy(() => import('../modules/user-hermes/UserHermesPage'))
  const ThanksPage = lazy(() => import('../modules/thanks/index'))
  const CashierPage = lazy(() => import('../modules/cashier/CashierPage'))
  const EndOfDayPage = lazy(() => import('../modules/end-of-day/EndOfDayPage'))
  const Balance = lazy(() => import('../modules/balance/BalanceRoutes'))
  const SetupBannerPage = lazy(() => import('../modules/setup-banner/SetupBannerPage'))
  const BulkUploadMenu = lazy(() => import('../modules/bulk-upload/BulkUploadRoutes'))
  const TransactionRoutes = lazy(() => import('../modules/transaction/TransactionRoutes'))

  return (
    <>
      <ModalCheckIn />
      <ModalCheckOut />
      <ModalAlertCheckout />
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path='/thank-you' component={ThanksPage} exact />
          <Route path='/dashboard' component={DashboardWrapper} />
          <Route path='/user' component={UserPage} />
          <Route path='/brand' component={BrandPage} />
          <Route path='/menu' component={MenuPage} />
          <Route path='/store' component={StorePage} />
          <Route path='/user-hermes' component={UserHermesPage} />
          <Route path='/cashier' component={CashierPage} />
          <Route path='/end-of-day' component={EndOfDayPage} />
          <Route path='/balance' component={Balance} />
          <ProtectedRoute
            roles={['corporate', 'brand']}
            path='/banner'
            component={SetupBannerPage}
          />
          <ProtectedRoute
            roles={['corporate', 'brand']}
            path='/bulk-upload-menu/list'
            component={BulkUploadMenu}
          />
          <ProtectedRoute
            roles={['corporate', 'store']}
            path='/transaction'
            component={TransactionRoutes}
          />
          <Redirect from='/auth' to='/dashboard' />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
    </>
  )
}
