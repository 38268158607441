import React, {FC, ReactNode, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {getTitle} from 'src/app/utils/title-utils'
import {PageTitle} from 'src/_metronic/layout/core'

interface DashboardPageProps {
  children?: ReactNode
}

const DashboardPage: FC<DashboardPageProps> = ({children}: DashboardPageProps) => <>{children}</>

interface DashboardWrapperProps {
  children?: ReactNode
}

const DashboardWrapper: FC<DashboardWrapperProps> = ({children}: DashboardWrapperProps) => {
  const intl = useIntl()
  useEffect(() => {
    document.title = getTitle('Dashboard')
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage {...{children}} />
    </>
  )
}

export {DashboardWrapper}
