import {useCallback, useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {useHistory} from 'react-router-dom'

export interface PermissionQuery {
  code?: string
  access?: string
}

export function usePermissions(pageQuery?: PermissionQuery) {
  const history = useHistory()
  const user: any = useSelector<RootState>(({auth}) => auth.user ?? {}, shallowEqual)
  const permissions = useSelector<RootState>(
    ({auth}) => auth.permissions ?? {},
    shallowEqual
  ) as Record<string, string[]>

  const hasAccess = useCallback(
    (q?: PermissionQuery) => {
      const code = q?.code ?? pageQuery?.code
      if (!code) return true
      return Boolean(
        permissions[code]?.find((data) => data === (q?.access ?? pageQuery?.access ?? 'read'))
      )
    },
    [pageQuery?.access, pageQuery?.code, permissions, user]
  )

  const canAccessPage = useMemo(() => hasAccess(), [hasAccess])

  const accessRedirect = useCallback(
    (access?: string, path?: string) => {
      if (access) {
        if (canAccessPage && hasAccess({access})) {
          return
        }
        if (path) return history.push(path)
        return history.goBack()
      }

      return
    },
    [canAccessPage, hasAccess]
  )
  return {hasAccess, canAccessPage, accessRedirect}
}
