/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'

const PrinterSettingListener: FC = () => {
  const localSettings = useSelector<RootState>(
    ({printer}) => ({printer_name: printer.printer_name, width: printer.width}),
    shallowEqual
  ) as {printer_name?: string; width?: number}
  useEffect(() => {
    if (window.eApi?.settingPrinter)
      window.eApi?.settingPrinter(localSettings.printer_name, localSettings.width)
  }, [localSettings.printer_name, localSettings.width])

  return <></>
}

export default PrinterSettingListener
