import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {AuthModel} from '../models/AuthModel'
import {CashierModel, UserModel} from '../models/UserModel'
import {getProfile} from './AuthCRUD'

namespace AuthRedux {
  const STORAGE_KEY = process.env.REACT_APP_STORAGE_KEY || 'hermes-auth'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    Login: '[Login] Action',
    Logout: '[Logout] Action',
    Register: '[Register] Action',
    UserRequested: '[Request User] Action',
    RefreshToken: '[Refresh Token] Action',
    UserLoaded: '[Load User] Auth API',
    SetUser: '[Set User] Action',
    SetShowModalCheckIn: '[Set Modal Check In] Action',
    SetShowModalCheckOut: '[Set Modal Check Out] Action',
    SetShowModalAlertCheckIn: '[Set Modal Alert CheckIn] Action',
    SetCashier: '[Set Cashier] Action',
    SetCheckIn: '[Set Check In] Action',
    FetchingUser: '[Fetching User] Action',
  }

  const initialAuthState: IAuthState = {
    user: undefined,
    accessToken: undefined,
    refreshToken: undefined,
    permissions: undefined,
    showModalCheckIn: false,
    showModalCheckOut: false,
    showModalAlertCheckIn: false,
    cashier: undefined,
    checkIn: false,
  }

  export interface IAuthState {
    fetchingUser?: FetchingUserState
    user?: UserModel
    accessToken?: string
    refreshToken?: string
    permissions?: Record<string, string[]>
    showModalCheckIn?: boolean
    showModalCheckOut?: boolean
    showModalAlertCheckIn?: boolean
    cashier?: CashierModel
    checkIn?: boolean
  }

  export type FetchingUserState = 'failed' | 'success' | 'loading'

  const extractPermissions = (user?: UserModel) => {
    const permissions: Record<string, string[]> = {}
    user?.role?.module_permissions?.forEach((perm: any) => {
      perm?.modules?.forEach((mod: any) => {
        permissions[mod.code] = [...(permissions[mod.code] ?? []), ...mod.active_permissions]
      })
    })
    Object.keys(permissions).forEach((record) => {
      permissions[record] = permissions[record].filter((v, i, self) => self.indexOf(v) === i)
    })
    return permissions
  }

  export const reducer = persistReducer(
    {
      storage,
      key: STORAGE_KEY,
      whitelist: [
        'accessToken',
        'refreshToken',
        'user',
        'permissions',
        'cashier',
        'showModalCheckIn',
        'showModalCheckOut',
        'showModalAlertCheckIn',
        'checkIn',
      ],
    },
    (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState> = {type: ''}) => {
      switch (action.type) {
        case actionTypes.Login: {
          const accessToken = action.payload?.accessToken
          const refreshToken = action.payload?.refreshToken
          const user = action.payload?.user
          const permissions = extractPermissions(user)
          return {accessToken, refreshToken, user, permissions}
        }

        case actionTypes.Register: {
          const accessToken = action.payload?.accessToken
          const refreshToken = action.payload?.refreshToken
          return {accessToken, refreshToken, user: undefined}
        }

        case actionTypes.Logout: {
          return initialAuthState
        }

        case actionTypes.UserRequested: {
          return {...state, user: undefined}
        }

        case actionTypes.RefreshToken: {
          return {
            ...state,
            accessToken: action.payload?.accessToken ?? state.accessToken,
            refreshToken: action.payload?.refreshToken ?? state.refreshToken,
          }
        }

        case actionTypes.UserLoaded:
        case actionTypes.SetUser: {
          const user = action.payload?.user
          const permissions = extractPermissions(user)
          return {...state, user, permissions, fetchingUser: 'success' as FetchingUserState}
        }

        case actionTypes.SetCashier: {
          const cashier = action.payload?.cashier
          return {...state, cashier}
        }

        case actionTypes.SetShowModalCheckIn: {
          const showModalCheckIn = action.payload?.showModalCheckIn
          return {...state, showModalCheckIn}
        }

        case actionTypes.SetShowModalCheckOut: {
          const showModalCheckOut = action.payload?.showModalCheckOut
          return {...state, showModalCheckOut}
        }

        case actionTypes.SetShowModalAlertCheckIn: {
          const showModalAlertCheckIn = action.payload?.showModalAlertCheckIn
          return {...state, showModalAlertCheckIn}
        }

        case actionTypes.SetCheckIn: {
          const checkIn = action.payload?.checkIn
          return {...state, checkIn}
        }

        case actionTypes.FetchingUser: {
          const fetchingUser = action.payload?.fetchingUser
          return {...state, fetchingUser}
        }

        default:
          return state
      }
    }
  )

  export const actions = {
    login: (accessToken: string, refreshToken: string) => ({
      type: actionTypes.Login,
      payload: {accessToken, refreshToken},
    }),
    register: (accessToken: string, refreshToken: string) => ({
      type: actionTypes.Register,
      payload: {accessToken, refreshToken},
    }),
    logout: () => ({type: actionTypes.Logout}),
    requestUser: () => ({
      type: actionTypes.UserRequested,
    }),
    fulfillToken: ({accessToken, refreshToken}: AuthModel) => ({
      type: actionTypes.RefreshToken,
      payload: {accessToken, refreshToken},
    }),
    fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
    setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
    setCashier: (cashier: CashierModel) => ({type: actionTypes.SetCashier, payload: {cashier}}),
    setModalCheckIn: (showModalCheckIn: boolean) => ({
      type: actionTypes.SetShowModalCheckIn,
      payload: {showModalCheckIn},
    }),
    setModalCheckOut: (showModalCheckOut: boolean) => ({
      type: actionTypes.SetShowModalCheckOut,
      payload: {showModalCheckOut},
    }),
    setModalAlertCheckIn: (showModalAlertCheckIn: boolean) => ({
      type: actionTypes.SetShowModalAlertCheckIn,
      payload: {showModalAlertCheckIn},
    }),
    setCheckIn: (checkIn: boolean) => ({type: actionTypes.SetCheckIn, payload: {checkIn}}),
    setFetchingUser: (fetchingUser: 'failed' | 'success' | 'loading') => ({
      type: actionTypes.FetchingUser,
      payload: {fetchingUser},
    }),
  }

  export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
      yield put(actions.requestUser())
    })

    yield takeLatest(actionTypes.Logout, function* loginSaga() {
      yield localStorage.clear()
    })

    yield takeLatest(actionTypes.Register, function* registerSaga() {
      // yield put(actions.requestUser())
    })

    yield takeLatest(actionTypes.UserRequested, function* userRequested(_action: any) {
      try {
        yield put(actions.setFetchingUser('loading'))
        const {
          data: {data: user},
        } = yield getProfile()

        yield put(actions.fulfillUser(user))
      } catch {
        yield put(actions.setFetchingUser('failed'))
      }
    })
  }
}

export default AuthRedux
