/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {getTitle} from 'src/app/utils/title-utils'
import {toAbsoluteUrl} from 'src/_metronic/helpers'
import {LegalData} from '../models/LegalData'
import {getPrivacyPolicy} from '../redux/LegalCRUD'

const PrivacyPolicy: React.FC = () => {
  const [remote, setRemote] = useState<{data?: LegalData; error?: boolean}>()
  const history = useHistory()

  useEffect(() => {
    document.title = getTitle('Kebijakan Privasi')
    getPrivacyPolicy()
      .then((value) => {
        const data = value.data.data?.find((it) => it.name === 'privacy_hermes_id')
        if (!data) {
          setRemote({error: true})
          return
        }
        setRemote({data})
      })
      .catch(() => {
        setRemote({error: true})
      })
  }, [])

  return (
    <div
      className='h-100 d-flex flex-column flex-column-fluid py-10 pb-lg-20'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/background/bg-3.jpg')})`,
      }}
    >
      <div className='container'>
        <button onClick={() => history.goBack()} className='btn btn-secondary mb-6'>
          Kembali
        </button>
      </div>
      {remote?.data ? (
        <div className='container flex-fill d-flex flex-column'>
          <div className='card flex-fill'>
            <div className='card-header d-flex align-items-center'>
              <div className='flex-fill fs-2 fw-bolder'>Kebijakan Privasi</div>
              <div>
                Last updated: {moment(remote?.data?.updated_at).locale('id').format('DD MMM YYYY')}
              </div>
            </div>
            <div className='card-body flex-fill overflow-scroll position-relative'>
              <div
                className='position-absolute'
                style={{
                  left: '2rem',
                  right: '2rem',
                  top: '2.25rem',
                  bottom: '2.25rem',
                }}
                dangerouslySetInnerHTML={{__html: remote?.data?.value ?? ''}}
              ></div>
            </div>
          </div>
        </div>
      ) : (
        <div className='container'>
          {!remote?.error ? 'Loading...' : 'Error, try to refresh the page'}
        </div>
      )}
    </div>
  )
}

export default PrivacyPolicy
