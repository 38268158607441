/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-imports */
import {MenuStockAvailability, MenuAddon} from '../../brand/models/Menu'
import {Store} from '../../store/models/Store'
import {Address} from './Address'
import {Courier} from './Courier'
import {Customer} from './Customer'
import {Language} from './Language'
import {OrderStatus} from './OrderStatus'
import {PaymentMethod} from './PaymentMethod'
import moment from 'moment'
import {Cashier} from '../../cashier/models/Cashier'
import {OrderFee} from './Fee'

export enum OrderDeliveryCourierType {
  MANUAL = 'MANUAL',
  SERVICE = 'SERVICE',
}

export enum OrderGroup {
  INPROCESS = 'INPROCESS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  REFUND = 'REFUND',
}

export interface Variation {
  id?: string
  name?: string
  price?: number
  sequence?: number
  stock_available?: boolean
}

export enum OrderDeliveryStatus {
  FINDING_DRIVER = 'FINDING_DRIVER',
  DRIVER_FOUND = 'DRIVER_FOUND',
  COMPLETED = 'COMPLETED',
  DRIVER_NOT_FOUND = 'DRIVER_NOT_FOUND',
  CANCELLED = 'CANCELLED',
}

export const OrderDeliveryStatusName: Record<string, string> = {
  [OrderDeliveryStatus.FINDING_DRIVER]: 'Mencari driver',
  [OrderDeliveryStatus.DRIVER_FOUND]: 'Driver ditemukan',
  [OrderDeliveryStatus.DRIVER_NOT_FOUND]: 'Driver tidak ditemukan',
  [OrderDeliveryStatus.CANCELLED]: 'Pencarian driver dibatalkan',
}

export const OrderDeliveryStatusColor: Record<string, string> = {
  [OrderDeliveryStatus.FINDING_DRIVER]: '',
  [OrderDeliveryStatus.DRIVER_FOUND]: 'text-info',
  [OrderDeliveryStatus.DRIVER_NOT_FOUND]: 'text-danger',
  [OrderDeliveryStatus.CANCELLED]: 'text-danger',
}

export function needDeliveryAction(status: OrderDeliveryStatus, type?: OrderDeliveryCourierType) {
  return (
    type !== OrderDeliveryCourierType.MANUAL &&
    [
      OrderDeliveryStatus.FINDING_DRIVER,
      OrderDeliveryStatus.DRIVER_NOT_FOUND,
      OrderDeliveryStatus.CANCELLED,
    ].includes(status)
  )
}

export function canManualDeliver(status: OrderDeliveryStatus, type?: OrderDeliveryCourierType) {
  return (
    type !== OrderDeliveryCourierType.MANUAL &&
    [OrderDeliveryStatus.DRIVER_NOT_FOUND, OrderDeliveryStatus.CANCELLED].includes(status)
  )
}

export enum OrderStatusCode {
  CONFIRMED = 'CONFIRMED',
  PREPARING = 'PREPARING',
  COOKING = 'COOKING',
  PACKAGING = 'PACKAGING',
  READY_TO_PICKUP = 'READY_TO_PICKUP',
  ENROUTE = 'ENROUTE',
  COMPLETED = 'COMPLETED',
  PAYMENT_EXPIRED = 'PAYMENT_EXPIRED',
  CANCELLED_BY_STORE = 'CANCELLED_BY_STORE',
  CANCELLED_BY_SYSTEM = 'CANCELLED_BY_SYSTEM',
  CANCELLED_BY_ADMIN = 'CANCELLED_BY_ADMIN',
  CANCELLED_BY_CUSTOMER = 'CANCELLED_BY_CUSTOMER',
  HOLD = 'HOLD',
}

export const OrderStatusFilter: Record<string, string> = {
  CONFIRMED: 'Baru',
  PREPARING: 'Disiapkan',
  COOKING: 'Sedang Dimasak',
  PACKAGING: 'Pengemasan',
  READY_TO_PICKUP: 'Siap Diambil',
  ENROUTE: 'Dalam Perjalanan',
}

export const OrderStatusActionName: Record<string, string> = {
  CONFIRMED: 'Terima',
  PREPARING: 'Siap Diambil',
  COOKING: 'Siap Diambil',
  PACKAGING: 'Siap Diambil',
  READY_TO_PICKUP: 'Sudah Diambil',
  ENROUTE: 'Selesai',
}

export const OrderStatusActionIcon: Record<string, string> = {
  CONFIRMED: '/media/icons/efood/IconCheck.svg',
  PREPARING: '/media/icons/efood/IconKnife.svg',
  COOKING: '/media/icons/efood/IconKnife.svg',
  PACKAGING: '/media/icons/efood/IconKnife.svg',
  READY_TO_PICKUP: '/media/icons/efood/IconCart.svg',
  ENROUTE: '/media/icons/efood/IconCheck.svg',
}

export const OrderStatusBadge: Record<string, string> = {
  CONFIRMED: 'badge-light-secondary',
  PREPARING: 'badge-light-warning',
  COOKING: 'badge-light-warning',
  PACKAGING: 'badge-light-warning',
  READY_TO_PICKUP: 'badge-light-success',
  ENROUTE: 'badge-light-success',
  COMPLETED: 'badge-light-success',
  PAYMENT_EXPIRED: 'badge-light-danger',
  CANCELLED_BY_STORE: 'badge-light-danger',
  CANCELLED_BY_SYSTEM: 'badge-light-danger',
  CANCELLED_BY_ADMIN: 'badge-light-danger',
  CANCELLED_BY_CUSTOMER: 'badge-light-danger',
}

export interface VariantSelected {
  id?: string
  name?: string
  price?: number
  sequence?: number
  idSection?: string
  nameSection?: string
}

export interface AddOn {
  uniqueId?: string
  addons?: MenuAddon
  variantsSelected?: VariantSelected[]
  qty?: number
  totalPrice?: number
}

export interface CartPayload {
  uniqId?: string
  quantity?: number
  menu: {
    id?: string
    photo?: string
    name?: string
    description?: string
    status?: string
    recomendation: false
    merchant_id?: string
    sequence?: number
    created_at?: string
    updated_at?: string
    store_avilability_id?: string
    store_id?: string
    discounted_price?: number
    menu_prices?: {
      id?: string
      price?: number
      menu_category_prices: {
        id?: string
        name?: string
      }[]
      menu_sales_channels: {
        id?: string
        name?: string
        platform?: string
      }[]
    }[]
    variations: {
      id?: string
      name?: string
      price?: number
      sequence?: number
      stock_available?: boolean
      variation: Variation[]
    }[]
    stock_available?: boolean
    menu_stock_availability?: MenuStockAvailability[]
  }
  storeAvilabiltyId?: string
  variantSelected: VariantSelected[]
  note?: string
  discounted_price?: number
  price?: number
  priceTotal?: number
  totalPrice?: number
  addOns?: AddOn[]
}

export interface Order {
  address_id?: string
  address?: Address
  cancellation_reason_by_customer_id?: string
  cancellation_reason_by_store?: {
    languages?: Language[]
  }
  cancellation_reason_empty_stocks?: {
    menu_ids?: string[]
    variation_ids?: string[]
  }
  cancellation_reason_by_customer?: {
    languages: Language[]
  }
  cart_payload?: CartPayload[]
  courier_id?: string
  courier?: Courier
  created_at?: string
  customer_id?: string
  customer?: Customer
  deleted_at?: string
  delivery_driver_name?: string
  delivery_driver_phone?: string
  delivery_courier_type?: string
  delivery_courier_name?: string
  delivery_fee?: number
  delivery_order_id?: string
  admin_fee?: number
  delivery_status?: string
  delivery_type?: string
  histories?: {
    id?: string
    status?: OrderStatus
    created_at?: string
    updated_at?: string
  }[]
  id?: string
  no?: string
  payment_method_id?: string
  payment_method?: PaymentMethod
  pickup_time?: string
  platform?: string
  sales_channel_id?: string
  status: OrderStatus
  store_id?: string
  time_left_to_accept?: number
  total?: number
  total_payment?: number
  shopping_discount_total?: number
  delivery_discount_total?: number
  updated_at?: string
  store?: Store
  sales_channel?: {
    default_sales_channel_id?: string
    icon?: string
    id?: string
    merchant_id?: string
    name?: string
    platform?: string
    status?: string
  }
  cashier: Cashier
  transaction_date?: string
  completed_at?: string
  gmt_offset?: number
  additional_fees?: OrderFee[]
  offline_payment_method?: OfflinePaymentMethod
  offline_payment_method_id?: string
  offline_payment_reference_no?: string
}

export interface OfflinePaymentMethod {
  id?: string
  is_cash?: boolean
  logo?: string
  name?: string
  require_reference_no?: boolean
  sequence?: number
  status?: string
  created_at?: string
  updated_at?: string
}

export function canPrintOrder(order: Order): boolean {
  // 1800000 = 30 minute
  return (
    order.status.group !== OrderGroup.COMPLETED ||
    (Boolean(order.completed_at) && moment(order.completed_at).diff(moment()) > -1800000)
  )
}

export function hasCancellableReason(order: Order) {
  return (
    Boolean(order.cancellation_reason_by_store) ||
    Boolean(order.cancellation_reason_empty_stocks) ||
    Boolean(order.cancellation_reason_by_customer)
  )
}

export function getCancellationReason(order: Order) {
  if (order.cancellation_reason_by_store)
    return order.cancellation_reason_by_store?.languages?.find((it) => it.lang === 'id')?.name
  if (order.cancellation_reason_empty_stocks) {
    const menu = order.cancellation_reason_empty_stocks.menu_ids ?? []
    const variation = order.cancellation_reason_empty_stocks.menu_ids ?? []
    if (variation.length > 0 && menu.length > 0) return 'Menu/variasi habis'
    else if (variation.length > 0) return 'Variasi habis'
    else return 'Menu habis'
  }
  if (order.cancellation_reason_by_customer)
    return order.cancellation_reason_by_customer.languages.find((it) => it.lang === 'id')?.name
}

export function disableContinueOrderStep(order: Order): boolean {
  return (
    order.delivery_type === 'DELIVERY' &&
    (order.delivery_courier_type as OrderDeliveryCourierType) !== OrderDeliveryCourierType.MANUAL &&
    (order.status?.status_code as OrderStatusCode) === OrderStatusCode.READY_TO_PICKUP &&
    needDeliveryAction(order.delivery_status as OrderDeliveryStatus)
  )
}

export interface UpdateParamsModel {
  store_id: string
  order_id: string
  type: 'preparing' | 'cooking' | 'packaging' | 'ready-to-pickup' | 'enroute' | 'completed' | string
}
export interface OrderQuery {
  no?: string
  groups?: string[]
  delivery_type?: string
  statuses?: string[]
}

export interface RejectOrderData {
  reason: string
  is_menu_empty: boolean
  menu_ids: string[]
  is_variation_empty: boolean
  variation_ids: string[]
  other_reasons: string
}

export const RejectOrderInitial: RejectOrderData = {
  reason: '',
  is_menu_empty: false,
  menu_ids: [],
  is_variation_empty: false,
  variation_ids: [],
  other_reasons: '',
}

export interface ManualOrderData {
  courier_name: string
  driver_name: string
  driver_phone: string
}

export const ManualOrderInitial: ManualOrderData = {
  courier_name: '',
  driver_name: '',
  driver_phone: '',
}

export enum RejectOrderReason {
  MENU_VARIASI_HABIS = 'stocks',
  STORE_TIDAK_BEROPERASIONAL = 'operational',
  STORE_SIBUK = 'busy',
  LAINNYA = 'other',
}

export const RejectOrderReasonText = {
  [RejectOrderReason.MENU_VARIASI_HABIS]: 'Menu/variasi habis',
  [RejectOrderReason.STORE_TIDAK_BEROPERASIONAL]: 'Store tidak beroperasional',
  [RejectOrderReason.STORE_SIBUK]: 'Store sibuk',
  [RejectOrderReason.LAINNYA]: 'Komplain',
}

export interface OrderParam {
  page?: number
  limit?: number
  no?: number | string
  store_id?: string
  merchant_id?: string
  groups?: string[]
  delivery_type?: string
  statuses?: string[]
  sales_channel_id?: string
}
