/* eslint-disable unused-imports/no-unused-imports */
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import Select, {SelectComponentsConfig} from 'react-select'
import {ReactSelectMetronicTheme} from './CustomReactSelect'
import * as Yup from 'yup'
import {
  getBusinessDate,
  getCurrentShift,
  getListManager,
  setCashierCheckIn,
  verifyLogin,
} from '../modules/auth/redux/AuthCRUD'
import {RootState} from 'src/setup'
import {CashierModel, UserModel} from '../modules/auth/models/UserModel'
import useDebounce from '../hooks/useDebounce'
import InlineSVG from 'react-inlinesvg/esm'
import moment from 'moment'
import 'moment/locale/id'
import InputMoney from './InputMoney'
import AuthRedux from '../modules/auth/redux/AuthRedux'
import {useHistory} from 'react-router'
import {getErrorMessage} from '../utils/api-utils'

const initialValues = {
  manager: {
    label: '',
    value: '',
    email: '',
  },
  password: '',
  cash: 0,
}

const validationSchema = Yup.object().shape({
  manager: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
      email: Yup.string(),
    })
    .required('Nama Manager wajib dipilih'),
  password: Yup.string()
    .min(6, 'Password minimal 6 karakter')
    .required('Password Manager wajib diisi'),
  cash: Yup.number().required('Petty Cash wajib diisi'),
})

const ModalCheckIn = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [bDate, setBDate] = useState<Date | undefined>(undefined)
  const [managers, setManagers] = useState<{value: string; label: string}[]>([])
  const dispatch = useDispatch()
  const user = useSelector<RootState>(({auth}: any) => auth.user, shallowEqual) as UserModel
  const checkIn = useSelector<RootState>(({auth}: any) => auth.checkIn, shallowEqual) as boolean
  // const cashier = useSelector<RootState>(
  //   ({auth}: any) => auth.cashier,
  //   shallowEqual
  // ) as CashierModel
  const showModalCheckIn = useSelector<RootState>(
    ({auth}: any) => auth.showModalCheckIn,
    shallowEqual
  ) as boolean
  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema,
    onSubmit: () => {},
  })
  const passwordDebounce = useDebounce(formik.values.password, 500)
  const history = useHistory()

  const businessDate = async () => {
    try {
      const res = await getBusinessDate(user?.store_id)
      setBDate(res.data.data?.business_date as Date)
    } catch (error) {
      setBDate(new Date())
    }
  }

  const handleCheckIn = () => {
    setLoading(true)
    if (page === 1) {
      verifyLogin(formik.values.manager.value, passwordDebounce)
        .then(() => {
          setPage(2)
          setLoading(false)
          businessDate()
        })
        .catch((e) => {
          formik.setFieldError('password', getErrorMessage(e, true) || 'Password tidak sesuai')
          setLoading(false)
        })
    } else {
      setCashierCheckIn(formik.values.manager.value, Number(formik.values.cash))
        .then(() => {
          setLoading(false)
          dispatch(AuthRedux.actions.setModalCheckIn(false))
          dispatch(AuthRedux.actions.setCheckIn(true))
        })
        .catch(() => {
          formik.setFieldError('cash', 'Kasir sudah check in')
          dispatch(AuthRedux.actions.setCheckIn(true))
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    if (
      user?.store &&
      user?.store?.service_addons?.find((item) => item.code === 'pos') &&
      user?.store?.merchant?.is_pos_checkin_enabled &&
      user?.role?.special_role &&
      user?.role?.special_role?.code === 'store_cashier'
    ) {
      getCurrentShift()
        .then((res) => {
          const data: CashierModel = res.data.data
          const startDate = new Date(data.start)
          const currentDate = new Date()
          if (startDate.getDay() === currentDate.getDay() || data?.inuse) {
            dispatch(AuthRedux.actions.setCashier(data))
            if (!data?.inuse) {
              dispatch(AuthRedux.actions.setModalCheckIn(true))
            } else {
              dispatch(AuthRedux.actions.setCheckIn(true))
            }
          } else {
            history.push('/logout')
            dispatch(AuthRedux.actions.setModalAlertCheckIn(true))
          }
        })
        .catch(() => {
          history.push('/logout')
          dispatch(AuthRedux.actions.setModalAlertCheckIn(true))
        })
    }

    if (user?.store?.id) {
      getListManager(user?.store?.id, {limit: 999999})
        .then((value) => {
          const items = value.data.data?.items
          setManagers(items?.map((it: any) => ({value: it.id, label: it.name, email: it.email})))
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    }
  }, [user])

  return (
    <Modal
      id='kt_modal_kitchen_display'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={showModalCheckIn && !checkIn}
      onHide={() => {}}
      centered
    >
      <div className='card'>
        <div className='modal-header py-2 d-flex justify-space-between py-7'>
          <span className='fs-3 fw-bolder'>Check In</span>
        </div>

        {page === 1 ? (
          <div className='card-body'>
            <p className='mb-10 fs-6 fw-bolder'>Selamat datang, {user?.name} !</p>
            <form autoComplete='off'>
              <div className='mb-10'>
                <label className='flex-fill form-label fs-6 fw-bold text-gray-800'>
                  Nama Manager*
                </label>
                <Select
                  autoComplete='off'
                  components={ReactSelectMetronicTheme as SelectComponentsConfig<any, true>}
                  value={formik.getFieldProps('manager').value}
                  onChange={(value: any) => {
                    formik.setFieldValue('manager', value)
                  }}
                  options={managers}
                />
                {formik.touched.manager && formik.errors.manager && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.manager}</span>
                  </div>
                )}
              </div>

              <div>
                <label className='flex-fill form-label fs-6 fw-bold text-gray-800'>
                  Password Manager*
                </label>
                <input
                  {...formik.getFieldProps('password')}
                  className='form-control form-control-lg form-control-solid'
                  type='password'
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                )}
              </div>
            </form>
          </div>
        ) : (
          <div className='card-body'>
            <div className='d-flex align-items-center mb-2'>
              <InlineSVG src='/media/icons/efood/IconStorePrimary.svg' />
              <span className='ms-3 fs-6 fw-bold text-gray-600'>Store</span>
            </div>
            <p className='fw-bold fs-6 mb-10'>{user?.store?.name}</p>
            <div className='d-flex align-items-center mb-2'>
              <InlineSVG src='/media/icons/efood/IconClockPrimary.svg' />
              <span className='ms-3 fs-6 fw-bold text-gray-600'>Waktu Check In</span>
            </div>
            <table className='mb-10'>
              <tbody>
                <tr>
                  <td className='pb-3'>Tanggal Bisnis</td>
                  <td className='fw-bold fs-6 pb-3'>
                    {`: ${moment(bDate).format('dddd, DD/MM/YYYY')} ${moment(new Date()).format(
                      'HH:mm'
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td className='pe-5 pb-3'>Tanggal Sistem</td>
                  <td className='fw-bold fs-6 pb-3'>
                    : {moment(new Date()).format('dddd, DD/MM/YYYY HH:mm')}
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <label className='flex-fill form-label fs-6 fw-bold text-gray-800'>Petty Cash*</label>
              <InputMoney
                value={String(formik.values.cash)}
                className='form-control form-control-lg form-control-solid'
                onChangeValue={(value) => formik.setFieldValue('cash', value)}
              />
              {formik.errors.cash && (
                <div className='fv-plugins-message-container text-danger mt-1'>
                  <span role='alert'>{formik.errors.cash}</span>
                </div>
              )}
            </div>
          </div>
        )}

        <div className='card-footer d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-secondary'
            disabled={
              loading ||
              (page === 2 ? !formik.values.cash : !formik.values.manager.value || !passwordDebounce)
            }
            onClick={handleCheckIn}
          >
            Check In
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalCheckIn
