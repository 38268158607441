import {put, takeLatest} from '@redux-saga/core/effects'
import {AxiosResponse} from 'axios'
import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import {BaseResponse} from 'src/app/models/api.types'
import {LocalCart, LocalCartItem} from '../models/LocalCart'
import {OrderStatistic} from '../models/OrderStatistic'
import {getOrderStats} from './TransactionCRUD'

namespace TransactionRedux {
  const STORAGE_KEY = 'tx-store'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    CountTransaction: '[Count Transaction] Action',
    SetCountTransaction: '[Set Count Transaction] Action',
    SetInprogressCountTransaction: '[Set Inprogress Count Transaction] Action',
    SetCompletedCountTransaction: '[Set Completed Count Transaction] Action',
    SetCancelledCountTransaction: '[Set Cancelled Count Transaction] Action',
    SetPreparingCountTransaction: '[Set Preparing Count Transaction] Action',
    SetCookingCountTransaction: '[Set Cooking Count Transaction] Action',
    SetReadyToPickupCountTransaction: '[Set Ready To Pickup Count Transaction] Action',
    SetPackagingCountTransaction: '[Set Packaging Count Transaction] Action',
    AddInprogressCountTransaction: '[Add Inprogress Count Transaction] Action',
    AddCompletedCountTransaction: '[Add Completed Count Transaction] Action',
    AddCancelledCountTransaction: '[Add Cancelled Count Transaction] Action',
    AddPreparingCountTransaction: '[Add Preparing Count Transaction] Action',
    AddCookingCountTransaction: '[Add Cooking Count Transaction] Action',
    AddReadyToPickupCountTransaction: '[Add Ready To Pickup Count Transaction] Action',
    AddPackagingCountTransaction: '[Add Packaging Count Transaction] Action',
    InitCart: '[Init Cart] Action',
    UpdateCartItem: '[Update Cart Item] Action',
  }

  export interface ActionData {
    CountTransaction: {
      store: string
    }
  }

  const initialState: IStoreState = {
    inprocess_count: undefined,
    completed_count: undefined,
    cancelled_count: undefined,
    preparing_count: undefined,
    cooking_count: undefined,
    ready_to_pickup_count: undefined,
    packaging_count: undefined,
    cart: undefined,
  }

  export interface IStoreState {
    inprocess_count?: number
    completed_count?: number
    cancelled_count?: number
    preparing_count?: number
    cooking_count?: number
    ready_to_pickup_count?: number
    packaging_count?: number
    cart?: LocalCart
  }

  export interface IStorePayload extends IStoreState {
    cartItems?: LocalCartItem[]
  }

  export const reducer = persistReducer(
    {
      storage,
      key: STORAGE_KEY,
      whitelist: [
        'inprocess_count',
        'completed_count',
        'cancelled_count',
        'preparing_count',
        'cooking_count',
        'ready_to_pickup_count',
        'packaging_count',
        'cart',
      ],
    },
    (state: IStoreState = initialState, action: ActionWithPayload<IStorePayload>) => {
      const {
        inprocess_count,
        completed_count,
        cancelled_count,
        preparing_count,
        cooking_count,
        ready_to_pickup_count,
        packaging_count,
        cart,
        cartItems,
      } = action.payload ?? {}

      switch (action.type) {
        case actionTypes.SetCountTransaction:
          return {
            ...state,
            inprocess_count,
            completed_count,
            cancelled_count,
            preparing_count,
            cooking_count,
            ready_to_pickup_count,
            packaging_count,
          }
        // case actionTypes.AddInprogressCountTransaction:
        // return {...state, inprocess_count: (state.inprocess_count ?? 0) + 1}
        // return {...state, inprocess_count, completed_count, cancelled_count}
        case actionTypes.SetInprogressCountTransaction:
          return {...state, inprocess_count}
        case actionTypes.SetCompletedCountTransaction:
          return {...state, completed_count}
        case actionTypes.SetCancelledCountTransaction:
          return {...state, cancelled_count}
        case actionTypes.SetPreparingCountTransaction:
          return {...state, preparing_count}
        case actionTypes.SetCookingCountTransaction:
          return {...state, cooking_count}
        case actionTypes.SetReadyToPickupCountTransaction:
          return {...state, ready_to_pickup_count}
        case actionTypes.SetPackagingCountTransaction:
          return {...state, packaging_count}
        case actionTypes.AddInprogressCountTransaction:
          return {...state, inprocess_count: (state.inprocess_count ?? 0) + (inprocess_count ?? 0)}
        case actionTypes.AddCompletedCountTransaction:
          return {...state, completed_count: (state.completed_count ?? 0) + (completed_count ?? 0)}
        case actionTypes.AddCancelledCountTransaction:
          return {...state, cancelled_count: (state.cancelled_count ?? 0) + (cancelled_count ?? 0)}
        case actionTypes.AddPreparingCountTransaction:
          return {...state, preparing_count: (state.preparing_count ?? 0) + (preparing_count ?? 0)}
        case actionTypes.AddCookingCountTransaction:
          return {...state, cooking_count: (state.cooking_count ?? 0) + (cooking_count ?? 0)}
        case actionTypes.AddReadyToPickupCountTransaction:
          return {
            ...state,
            ready_to_pickup_count:
              (state.ready_to_pickup_count ?? 0) + (ready_to_pickup_count ?? 0),
          }
        case actionTypes.AddPackagingCountTransaction:
          return {...state, packaging_count: (state.packaging_count ?? 0) + (packaging_count ?? 0)}
        case actionTypes.InitCart:
          return {...state, cart}
        case actionTypes.UpdateCartItem:
          return {...state, cart: state.cart ? {...state.cart, items: cartItems ?? []} : undefined}
        default:
          return state
      }
    }
  )

  export const actions = {
    countTransaction: (store: string) => ({
      type: actionTypes.CountTransaction,
      store,
    }),
    setCountTransaction: (
      inprocess_count?: number,
      completed_count?: number,
      cancelled_count?: number,
      preparing_count?: number,
      cooking_count?: number,
      ready_to_pickup_count?: number,
      packaging_count?: number
    ) => ({
      type: actionTypes.SetCountTransaction,
      payload: {
        inprocess_count,
        completed_count,
        cancelled_count,
        preparing_count,
        cooking_count,
        ready_to_pickup_count,
        packaging_count,
      },
    }),
    setInprogressCountTransaction: (count: number) => ({
      type: actionTypes.SetInprogressCountTransaction,
      payload: {inprocess_count: count},
    }),
    setCompletedCountTransaction: (count: number) => ({
      type: actionTypes.SetCompletedCountTransaction,
      payload: {completed_count: count},
    }),
    setCancelledCountTransaction: (count: number) => ({
      type: actionTypes.SetCancelledCountTransaction,
      payload: {cancelled_count: count},
    }),
    SetPreparingCountTransaction: (count: number) => ({
      type: actionTypes.SetPreparingCountTransaction,
      payload: {preparing_count: count},
    }),
    SetCookingCountTransaction: (count: number) => ({
      type: actionTypes.SetCookingCountTransaction,
      payload: {cooking_count: count},
    }),
    SetReadyToPickupCountTransaction: (count: number) => ({
      type: actionTypes.SetReadyToPickupCountTransaction,
      payload: {ready_to_pickup_count: count},
    }),
    SetPackagingCountTransaction: (count: number) => ({
      type: actionTypes.SetPackagingCountTransaction,
      payload: {packaging_count: count},
    }),
    addInprogressCountTransaction: (count: number = 1) => ({
      type: actionTypes.AddInprogressCountTransaction,
      payload: {inprocess_count: count},
    }),
    addCompletedCountTransaction: (count: number = 1) => ({
      type: actionTypes.AddCompletedCountTransaction,
      payload: {completed_count: count},
    }),
    addCancelledCountTransaction: (count: number = 1) => ({
      type: actionTypes.AddCancelledCountTransaction,
      payload: {cancelled_count: count},
    }),
    addPreparingCountTransaction: (count: number = 1) => ({
      type: actionTypes.AddPreparingCountTransaction,
      payload: {preparing_count: count},
    }),
    addCookingCountTransaction: (count: number = 1) => ({
      type: actionTypes.AddCookingCountTransaction,
      payload: {cooking_count: count},
    }),
    addReadyToPickupCountTransaction: (count: number = 1) => ({
      type: actionTypes.AddReadyToPickupCountTransaction,
      payload: {ready_to_pickup_count: count},
    }),
    addPackagingCountTransaction: (count: number = 1) => ({
      type: actionTypes.AddPackagingCountTransaction,
      payload: {packaging_count: count},
    }),
    initCart: (cart?: LocalCart) => ({
      type: actionTypes.InitCart,
      payload: {cart},
    }),
    updateCartItem: (cartItems?: LocalCartItem[]) => ({
      type: actionTypes.UpdateCartItem,
      payload: {cartItems},
    }),
  }

  export function* saga() {
    yield takeLatest(
      actionTypes.CountTransaction,
      function* countSaga(action: Action & ActionData['CountTransaction']) {
        try {
          const stats: AxiosResponse<BaseResponse<OrderStatistic>> = yield getOrderStats(
            action.store
          )
          yield put(
            actions.setCountTransaction(
              stats.data.data?.group_statuses?.INPROCESS ?? 0,
              stats.data.data?.group_statuses?.COMPLETED ?? 0,
              stats.data.data?.group_statuses?.REFUND ?? 0,
              stats.data.data?.statuses?.PREPARING ?? 0,
              stats.data.data?.statuses?.COOKING ?? 0,
              stats.data.data?.statuses?.READY_TO_PICKUP ?? 0,
              stats.data.data?.statuses?.PACKAGING ?? 0
            )
          )
        } catch (e) {}
      }
    )
  }
}

export default TransactionRedux
