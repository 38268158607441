import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useState, useEffect} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {getTitle} from 'src/app/utils/title-utils'
import {RootState} from 'src/setup'
import {usePageData} from 'src/_metronic/layout/core'
import * as Yup from 'yup'
import {updatePassword} from '../../auth/redux/AuthCRUD'
import AlertError from './_alert/Error'
import AlertSuccess from './_alert/Success'

export const ChangePassword = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [success, setSuccess] = useState('')

  // Variables
  const token: any = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const initialValues = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  }
  const profileSchema = Yup.object().shape({
    old_password: Yup.string().required('Password Saat Ini wajib diisi'),
    new_password: Yup.string().min(6, 'Minimal 6 karakter').required('Password Baru wajib diisi'),
    confirm_password: Yup.string()
      .required('Konfirmasi Password wajib diisi')
      .test('equal', 'Konfirmasi Password Baru harus sama dengan Password Baru.', function (v) {
        // Don't use arrow functions
        const ref = Yup.ref('new_password')
        return v === this.resolve(ref)
      }),
  })

  const {setPageDescription, setPageTitle} = usePageData()
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: profileSchema,
    onSubmit: (values, {setStatus, setSubmitting, setTouched, setFieldError}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          const {old_password, new_password} = values
          await updatePassword(`Bearer ${token}`, {old_password, new_password})
          setLoading(false)
          setSubmitting(false)
          setSuccess('Password berhasil diubah.')
          formik.setValues({
            old_password: '',
            new_password: '',
            confirm_password: '',
          })
          setTouched({
            old_password: false,
            new_password: false,
            confirm_password: false,
          })
        } catch (e: any) {
          setLoading(false)
          setSubmitting(false)
          let invalid = false
          try {
            console.log(e.response.data.message[0].constraint[0].code)
            invalid = e.response.data.message[0].constraint[0].code === 'INVALID_PASSWORD'
          } catch (e) {}
          if (invalid) setFieldError('old_password', 'Password Saat Ini salah. Coba lagi.')
          else setStatus('Password gagal diubah.')
        }
      }, 1000)
    },
  })

  // Hooks
  useEffect(() => {
    const title = 'Ubah Password'

    setPageTitle(title)
    setPageDescription(title)
    setTimeout(() => {
      document.title = getTitle(title)
    }, 100)
  }, [setPageTitle, setPageDescription])

  useEffect(() => {
    const {values, isSubmitting, status} = formik
    if (values.old_password && values.confirm_password && values.new_password) {
      setDisabled(false)
    } else if (isSubmitting) {
      setDisabled(true)
    } else {
      setDisabled(true)
    }

    if (status) {
      setTimeout(() => formik.setStatus(''), 5000)
    }
  }, [formik])

  useEffect(() => {
    if (success) setTimeout(() => setSuccess(''), 5000)
  }, [success])

  return (
    <div>
      <AlertSuccess message={success} handleClose={() => setSuccess('')} />
      <AlertError message={formik.status} handleClose={() => formik.setStatus('')} />

      <form className='form w-50' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>
            Password Saat Ini
          </label>
          <input
            placeholder=''
            {...formik.getFieldProps('old_password')}
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': formik.touched.old_password && formik.errors.old_password,
            })}
            type='password'
            name='old_password'
            autoComplete='off'
          />
          {formik.touched.old_password && formik.errors.old_password && (
            <div className='fv-plugins-message-container text-danger'>
              <span role='alert'>{formik.errors.old_password}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>Password Baru</label>
          <input
            placeholder=''
            {...formik.getFieldProps('new_password')}
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': formik.touched.new_password && formik.errors.new_password,
            })}
            type='password'
            name='new_password'
            autoComplete='off'
          />
          {formik.touched.new_password && formik.errors.new_password ? (
            <div className='fv-plugins-message-container text-danger'>
              <span role='alert'>{formik.errors.new_password}</span>
            </div>
          ) : (
            <div className='fv-plugins-message-container text-gray-500'>
              <span role='alert'>Password minimal 6 karakter</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>
            Konfirmasi (Ulang) Password Baru
          </label>
          <input
            placeholder=''
            {...formik.getFieldProps('confirm_password')}
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password,
            })}
            type='password'
            name='confirm_password'
            autoComplete='off'
          />
          {formik.touched.confirm_password && formik.errors.confirm_password && (
            <div className='fv-plugins-message-container text-danger'>
              <span role='alert'>{formik.errors.confirm_password}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex pb-lg-0'>
          <Link
            to='/dashboard'
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className={`btn btn-lg btn-light fw-bolder d-block me-4 ${
              formik.isSubmitting ? 'disabled' : ''
            }`}
          >
            Kembali
          </Link>{' '}
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-secondary fw-bolder'
            disabled={disabled || loading}
          >
            <span className='indicator-label'>Simpan</span>
            {loading && (
              <span className='indicator-progress'>
                Mohon Tunggu...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </div>
  )
}
