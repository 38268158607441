const LegalScreens = {
  PRIVACY_POLICY: {
    PATH: '/privacy-policy',
  },
  TERMS: {
    PATH: '/terms-of-service',
  },
}

export default LegalScreens
