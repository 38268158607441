import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useState, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {getTitle} from 'src/app/utils/title-utils'
import {usePageData} from 'src/_metronic/layout/core'
import * as Yup from 'yup'
import UpdateEmail from './_modals/UpdateEmail'
import ChangePhoneModal from './_modals/UpdatePhone'
import AlertSuccess from './_alert/Success'
import {RootState} from 'src/setup'
import AlertError from './_alert/Error'
import {getUserProfile, resendEmailVerification, updateProfile} from '../../auth/redux/AuthCRUD'
import css from 'src/scss/auth/components/input.module.scss'
import AuthRedux from '../../auth/redux/AuthRedux'
import {UserModel} from '../../auth/models/UserModel'

const initialValues = {
  name: '-',
  phone: '-',
  email: '-',
  nip: '-',
  brand: '-',
  role: '-',
}

const profileSchema = Yup.object().shape({
  name: Yup.string().required('Nama wajib diisi'),
})

export const Profile = () => {
  // States
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [show, setShow] = useState<'email' | 'phone' | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [failed, setFailed] = useState('')

  // Variables
  const dispatch = useDispatch()
  const {setPageDescription, setPageTitle} = usePageData()
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string
  const user = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: profileSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setIsLoading(true)
      setTimeout(async () => {
        try {
          await updateProfile(`Bearer ${accessToken}`, {name: values?.name})
          setIsLoading(false)
          setSubmitting(false)
          setSuccess('Data berhasil disimpan')
        } catch (_) {
          setIsLoading(false)
          setSubmitting(false)
          setStatus('Data gagal disimpan')
        }
      }, 1000)
    },
  })

  // Hooks
  useEffect(() => {
    const title = 'Profil Saya'

    setPageTitle(title)
    setPageDescription(title)
    setTimeout(() => {
      document.title = getTitle(title)
    }, 100)
  }, [setPageTitle, setPageDescription])

  const fetching = useSelector<RootState>(
    ({auth}) => auth.fetchingUser,
    shallowEqual
  ) as AuthRedux.FetchingUserState
  const [loaded, setLoaded] = useState<boolean>()
  useEffect(() => {
    dispatch(AuthRedux.actions.requestUser())
  }, [dispatch])
  useEffect(() => {
    if (loaded === undefined && fetching === 'loading') setLoaded(false)
    else if (loaded === false && fetching !== 'loading') {
      setLoaded(true)
    }
  }, [fetching, formik, loaded, user])

  useEffect(() => {
    if (user) {
      const {name, phone, email, nip, role, merchant} = user
      let resPhone
      if (phone[0] === '0') resPhone = phone.replace('0', '')
      if (String(phone).slice(0, 2) === '62') resPhone = String(phone).slice(2)
      if (name) initialValues.name = name
      if (resPhone) initialValues.phone = resPhone
      if (email) initialValues.email = email
      if (nip) initialValues.nip = nip
      if (merchant?.name) initialValues.brand = merchant?.name
      if (role) initialValues.role = role?.name
    }
  }, [user])

  useEffect(() => {
    if (success) {
      const getUser = () => {
        setTimeout(async () => {
          const result = await getUserProfile(`Bearer ${accessToken}`)
          dispatch(AuthRedux.actions.setUser(result.data.data))
        }, 2000)
      }

      setTimeout(() => setSuccess(''), 5000)
      getUser()
    }
  }, [accessToken, dispatch, success])

  useEffect(() => {
    const {status, setStatus} = formik

    if (status) setTimeout(() => setStatus(''), 5000)
  }, [formik])

  // Handlers

  const handleEmail = () => {
    if (user?.email_verified_at) {
      setShow('email')
    } else {
      if (user?.email) {
        setLoading(true)
        resendEmailVerification(user?.email)
          .finally(() => setLoading(false))
          .then((res) => {
            setSuccess('Berhasil mengirim ulang email verifikasi')
          })
          .catch((e) => {
            const {
              response: {data},
            } = e
            if (data.message[0].constraint[0].message) {
              setFailed(data.message[0].constraint[0].message)
            } else {
              setFailed('Gagal mengirim ulang email verifikasi')
            }
          })
      }
    }
  }

  return (
    <div>
      <UpdateEmail
        show={show === 'email'}
        handleClose={() => setShow(undefined)}
        handleSuccess={setSuccess}
        handleError={setFailed}
      />
      <ChangePhoneModal
        show={show === 'phone'}
        handleClose={() => setShow(undefined)}
        handleSuccess={setSuccess}
        handleError={setFailed}
      />
      <AlertSuccess message={success} handleClose={() => setSuccess('')} />
      <AlertError message={formik.status} handleClose={() => formik.setStatus('')} />
      <AlertError message={failed} handleClose={() => setFailed('')} />
      <div className={!loaded ? '' : 'd-none'}>Loading...</div>
      <div className={loaded && fetching === 'failed' ? '' : 'd-none'}>
        Error, try to refresh the page
      </div>

      <form
        className={clsx('form w-100 w-lg-50', {'d-none': !loaded || fetching === 'failed'})}
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>Nama</label>
          <input
            placeholder=''
            {...formik.getFieldProps('name')}
            className={clsx('form-control form-control-lg', 'form-control-solid', {
              'is-invalid': formik.touched.name && formik.errors.name,
            })}
            style={user?.store_id ? {backgroundColor: '#E3E6EF', color: '#7F8299'} : {}}
            type='text'
            name='name'
            autoComplete='off'
            disabled={user?.store_id ? true : false}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container text-danger'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>No. HP</label>
          <div className='row'>
            <div className={user?.store_id ? 'col-12' : 'col-9 col-md-9 col-lg-9'}>
              <div className={css.input_prefix}>
                <span style={{fontSize: 15, color: '#7F8299'}}>+62</span>
                <input
                  {...formik.getFieldProps('phone')}
                  className='form-control form-control-lg bg'
                  style={{backgroundColor: '#E3E6EF', color: '#7F8299'}}
                  type='text'
                  name='phone'
                  disabled
                />
              </div>
            </div>
            {!user?.store_id && (
              <div className='col-3 col-md-3 col-lg-3'>
                <button
                  type='button'
                  className='btn btn-lg btn-success'
                  onClick={() => setShow('phone')}
                >
                  Ubah
                </button>
              </div>
            )}
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>Email</label>
          <div className='row'>
            <div
              className={`col-9 ${
                user?.email_verified_at ? 'col-md-9 col-lg-9' : 'col-md-6 col-lg-6'
              }`}
            >
              <input
                {...formik.getFieldProps('email')}
                className={clsx('form-control form-control-lg flex-fill', {
                  'is-invalid': !user?.email_verified_at,
                })}
                style={{borderColor: '#e4e6ef', backgroundColor: '#E3E6EF', color: '#7F8299'}}
                type='text'
                name='email'
                disabled
              />
              {!user?.email_verified_at && (
                <div className='fv-plugins-message-container text-danger mt-1'>
                  <span role='alert'>Email belum diverifikasi</span>
                </div>
              )}
            </div>
            <div className={`col`}>
              <button
                type='button'
                className='btn btn-lg btn-success'
                onClick={handleEmail}
                disabled={loading}
              >
                {user?.email_verified_at ? 'Ubah' : 'Kirim ulang verifikasi'}
              </button>
            </div>
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>
            Nomor Induk Karyawan (NIK)
          </label>
          <input
            {...formik.getFieldProps('nip')}
            className='form-control form-control-lg'
            style={{backgroundColor: '#E3E6EF', color: '#7F8299'}}
            type='text'
            name='nip'
            disabled
          />
        </div>

        {user?.merchant_id && (
          <div className='fv-row mb-10'>
            <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>Brand</label>
            <input
              {...formik.getFieldProps('brand')}
              className='form-control form-control-lg'
              style={{backgroundColor: '#E3E6EF', color: '#7F8299'}}
              type='text'
              name='brand'
              disabled
            />
          </div>
        )}
        {user?.store_id && (
          <div className='fv-row mb-10'>
            <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>Store</label>
            <input
              className='form-control form-control-lg'
              style={{backgroundColor: '#E3E6EF', color: '#7F8299'}}
              type='text'
              name='brand'
              disabled
              value={user.store.name}
            />
          </div>
        )}

        <div className='fv-row mb-10'>
          <label className='flex-fill form-label fs-6 fw-bolder text-gray-800'>Role</label>
          <input
            {...formik.getFieldProps('role')}
            className='form-control form-control-lg'
            style={{backgroundColor: '#E3E6EF', color: '#7F8299'}}
            type='text'
            name='role'
            disabled
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex pb-lg-0'>
          <Link
            to='/dashboard'
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className={`btn btn-lg btn-light fw-bolder d-block me-4 ${
              formik.isSubmitting ? 'disabled' : ''
            }`}
          >
            Kembali
          </Link>{' '}
          {!user?.store_id && (
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-secondary fw-bolder'
              disabled={formik.isSubmitting}
            >
              <span className='indicator-label'>Simpan Perubahan</span>
              {isLoading && (
                <span className='indicator-progress'>
                  Mohon Tunggu...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
        {/* end::Form group */}
      </form>
    </div>
  )
}
