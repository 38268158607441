import { Action } from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

namespace CashierRedux {
  const STORAGE_KEY = 'hermes-brand'

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    GetListCashier: '[Get List Cashier] Action',
    SetSuccess: '[Set Success] Action',
    IsLoading: '[IsLoading] Action',
  }

  const initialCashierState: ICashierState = {
    data: undefined,
    isLoading: undefined,
    message: undefined,
  }

  export interface ICashierState {
    data?: any
    isLoading?: boolean
    message?: string
  }

  export const reducer = persistReducer(
    {
      storage,
      key: STORAGE_KEY,
      whitelist: ['data', 'isLoading', 'message'],
    },
    (state: ICashierState = initialCashierState, action: ActionWithPayload<ICashierState>) => {
      const data = action.payload?.data
      const message = action.payload?.message
      const isLoading = action.payload?.isLoading

      switch (action.type) {
        case actionTypes.GetListCashier:
          return { ...state, data, message }
        case actionTypes.IsLoading:
          return { ...state, isLoading }
        case actionTypes.SetSuccess:
          return { ...state, message }
        default:
          return state
      }
    }
  )

  export const actions = {
    getListCashier: (data: any, message: string) => ({
      type: actionTypes.GetListCashier,
      payload: { data, message },
    }),
    setIsLoading: (isLoading: boolean) => ({
      type: actionTypes.IsLoading,
      payload: { isLoading },
    }),
    setSuccess: (message: string) => ({
      type: actionTypes.SetSuccess,
      payload: { message },
    }),
  }
}

export default CashierRedux
